import React, {useEffect, useState} from "react";
import "../styles/DifficoltySelector.css";
import Label from "./Label";

interface ComponentProps {
    length: number;
    value: number | 1;
    setValue: (num: any) => void;
    label?: string;
    obbligatorio: boolean;
    type?: string;
    editable?: boolean;
}

const NumberGradientSelector: React.FC<ComponentProps> = ({ length, editable = true,  type = "difficulty", value, setValue, label, obbligatorio }) => {
    const [hoverIndex, setHoverIndex] = useState<number>(value);

    return (
        <div>
            <Label label={label} />
            <div className="diff-container" onMouseLeave={() => setHoverIndex(value)}>

                {type == "difficulty" && Array.from({length: length}).map((_, index) => (
                    <div
                        className={"cliccabile rectangle color-" + ((hoverIndex >= index + 1) ? String(index + 1) : (value >= index + 1) ? String(index + 1) : "transparent border border-primary" )}
                        key={index + 1}
                        style={{width: `${100 / length}%`}}
                        onMouseEnter={editable ? () => setHoverIndex(index + 1) : () => {}}
                        onClick={editable ? () => setValue(hoverIndex) : ()=>{}}
                    >
                        <center>{(value == index + 1) ? String(value) : '\u00A0'}</center>
                    </div>
                ))}
                {type === "stars" && Array.from({ length }).map((_, index) => (
                    <div
                        className="cliccabile star-container"
                        key={index + 1}
                        style={{ width: `${100 / length}%`, textAlign: "center" }}
                        onMouseEnter={editable ? () => setHoverIndex(index + 1) : () => {}}
                        onClick={editable ? () => setValue(hoverIndex) : ()=>{}}
                    >
                        <span
                            className={"star " + ((hoverIndex >= index + 1) || (value >= index + 1) ? "filled" : "empty")}
                            style={{ fontSize: "24px", cursor: "pointer" }}
                        >
                            {(hoverIndex >= index + 1 || value >= index + 1) ? "\u2605" : "\u2606"}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NumberGradientSelector;