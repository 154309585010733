import React, {useEffect, useState} from 'react';
import {
    addOrRemoveElementToCarousel,
    backend_path, backend_path_slash,
    doRequest, FeedbackFilterOptions, ItineraryFilterOptions, Option,
    ThemeOptions,
    useStateEmail, useStateFile, useStateModal, useStateMultiple, useStateSelect, useStateText,
} from "../utils";

import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import {useParams} from "react-router-dom";
import CarouselHead from "../generic_components/CarouselHead";
import "../styles/Carousel.css"
import List from "../generic_components/List";
import Filters from "../generic_components/Filters";
import filters from "../generic_components/Filters";

interface pageProps {
    title : string
}

const ViewFeedback: React.FC<pageProps> = ({title}) => {
    const { id } = useParams();
    const [PageCount, setPageCount] = useState<number>(-1);
    const [CurrentPageNumber, setCurrentPageNumber] = useState(1);

    const [ActiveFilters, FilterNumber, FilterSelected, setFilterNumber, setFilterSelected, getStateAtIndex, addStateAtIndex,updateStateAtIndex, deleteStateAtIndex, getFilterValues] = useStateMultiple();

    useEffect(() => {
        document.title = title
    }, []);

    const perPage = 5;

    return (
        <div className={"container"}>
            <Filters filterOptions={FeedbackFilterOptions} ActiveFilters={ActiveFilters} FilterNumber={FilterNumber} FilterSelected={FilterSelected} setFilterNumber={setFilterNumber} setFilterSelected={setFilterSelected} getStateAtIndex={getStateAtIndex} addStateAtIndex={addStateAtIndex} updateStateAtIndex={updateStateAtIndex} deleteStateAtIndex={deleteStateAtIndex} />
            <div className="row container-fluid py-0 jumbotron-login border"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <CarouselHead id={id} item_name={"Pagina feedback"}
                              countEndpoint={"/Itinerary/GetFeedback/Count"}
                              key={{...{ItineraryId : id}, ...getFilterValues()}}
                              key_parent={""} key_item={"CurrentPage"}
                              ItemCount={PageCount} setItemCount={setPageCount}
                              CurrentItemNumber={CurrentPageNumber} setCurrentItemNumber={setCurrentPageNumber}
                              selectOptions={ PageCount > 0 &&
                                   [...Array(PageCount)].map((_, index) => (
                                    { value : String(index), label : String(index + 1) , subtext : ""}
                                  ))
                              }
                              PerPageNumber={perPage}
                              filters={{...{ItineraryId : id}, ...getFilterValues()}}
                              innerItems={PageCount > 0 && [...Array(PageCount)].map((_, index) => (
                                      <List id={`${index}_itinerary_list`}
                                            isCarousel={true}
                                            CurrentPage={index + 1}
                                            PerPageNumber={perPage}
                                            endpoint={"/Itinerary/GetFeedback"}
                                            filters={{...{ItineraryId : id}, ...getFilterValues()}}/>
                              ))}/>
            </div>

        </div>
    );
};

export default ViewFeedback;