import React, {useEffect, useState} from 'react';
import {
    backend_path_slash,
    doRequest,
    extractTextFromHTML, truncateText,
    useStateEmail,
    useStateSelect,
    useStateText
} from "../utils";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import "../styles/Jumbotron.css"
import SubmitButton from "../generic_components/SubmitButton";
import "../styles/GenericLink.css"
import Card from "../generic_components/Card";
import ButtonIcon from "../generic_components/ButtonIcon";
import Carousel from "../generic_components/Carousel";
import CircleStars from "../generic_components/CircleStars";
import Label from "../generic_components/Label";

interface pageProps {
    title : string
}

const HomeNotLogged: React.FC<pageProps> = ({title}) => {

    useEffect(() => {
        document.title = title
    }, []);

    return (
        <div className="row container-fluid">
            <div className="row d-flex justify-content-center py-4">
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                    <h3>
                        BENVENUTO!
                    </h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-8 d-flex justify-content-center align-items-center">
                    <br/>
                    <h5>In questo portale potrai accedere ad una community per vedere e condividere itinerari
                        turistici</h5>
                </div>
            </div>
            <br/><br/><br/>
            <hr className={"carousel-head-hr"}/>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <h3 className={"min-vh-10"}>
                                Accedi ad itinerari creati dalla community
                            </h3>
                            <p>
                                Trova ispirazione dalle esperienze degli altri viaggiatori per pianificare la tua
                                prossima avventura.
                                Dalle spiagge alle vette, passando per le città, lasciati guidare attraverso itinerari
                                pensati per ogni tipo di turista.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h3 className={"min-vh-10"}>
                                Crea il tuo itinerario
                            </h3>
                            <p>
                                Crea il tuo itinerario in pochi clic, condividi la tua esperienza unica
                                e connettiti con una community di viaggiatori pronti a scambiarsi consigli e
                                ispirazioni.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h3 className={"min-vh-10"}>
                                Fai valere la tua opinione
                            </h3>
                            <p>
                                Puoi dare un feedback a tutti gli itinerari, ma non solo, puoi avviare discussioni sul
                                forum
                                per comunicare più direttamente con gli altri utenti e creator.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={"carousel-head-hr"}/>
            <div className="row">
                <div className="col-md-6">
                    <Label label={"Registrati ora!"} />
                    <SubmitButton label={"REGISTRATI"} onSubmit={() => {window.location.href="/SignUp"}} />
                </div>
                <div className="col-md-6">
                    <Label label={"Già registrato? Accedi subito"} />
                    <SubmitButton label={"ACCEDI"} onSubmit={() => {window.location.href="/Login"}} />
                </div>
            </div>

        </div>
    );
};

export default HomeNotLogged;