import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import "../styles/TextArea.css"
import {handleErrorCheck} from "../utils";

interface Props {
    id: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLDivElement>) => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
}

const TextArea: React.FC<Props> = ({ id, value, onChange, label, placeholder = '', obbligatorio = false, errore = "", check = true }) => {

    useEffect(() => {
        let textAreaRef = document.getElementById(id);
        if(textAreaRef && textAreaRef.innerHTML == "")
            textAreaRef.innerHTML = value;
    }, [value]);

    const defaultErrore = "Il campo deve essere solo testo"
    const [err, setErrore] = useState("");
    const checkErrori = async () => {
        await handleErrorCheck("String", value, setErrore, err, errore, defaultErrore);
    }

    return (
        <div style={{marginBottom: '16px'}}>
            <label htmlFor={id} style={{display: 'block', marginBottom: '8px', fontWeight: 'bold'}}>
                {label}{obbligatorio ? " (*)" : ""}
            </label>
            <div contentEditable={true}
                id={id}
                className={"textarea-size"}
                onInput={onChange}
                onBlur={checkErrori}
                style={{
                    padding: '8px',
                    fontSize: '16px',
                    width: '100%',
                    overflow: 'hidden',
                    resize: 'none',
                }}
            ></div>
        </div>
    );
};

export default TextArea;