import React, {useEffect, useState} from "react";
import "../styles/DifficoltySelector.css";
import {backend_path_slash, capFirstLetter} from "../utils";
import "../styles/Img.css"

interface ComponentProps {
    id : string;
    id_source?: string;
    value_override?: string;
    img_path? : string;
    img_path_backend? : string;
    param?: string;
    size_coefficient?: number;
}

const Img: React.FC<ComponentProps> = ({ id, param = "height", size_coefficient = 1, id_source, value_override, img_path, img_path_backend = "" }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const interval = setInterval(() => {
        let img = document.getElementById(id);
        if(img !== null){
            if(value_override !== undefined){
                img.style.setProperty(param, value_override, "important");
            }else if(id_source !== undefined){
                let source = document.getElementById(id_source);
                if (source !== null){
                    if(param == "height"){
                        img.style.setProperty(param, `${String(source.offsetHeight * size_coefficient)}px`, "");
                        img.style.setProperty("width", `auto`, "");
                    }
                    if(param == "width"){
                        img.style.setProperty(param, `${String(source.offsetWidth * size_coefficient)}px`, "");
                        img.style.setProperty("height", `auto`, "");
                    }
                    if(param == "both"){
                        img.style.setProperty("height", `${String(source.offsetHeight * size_coefficient)}px`, "");
                        img.style.setProperty("width", `${String(source.offsetWidth * size_coefficient)}px`, "");
                    }
                }
            }
        }
        }, 100)

        return () => clearInterval(interval);
    }, []);

    const path = img_path_backend ? (backend_path_slash + img_path_backend) : (img_path ? img_path : "");

    return (
        <div>
            {((img_path_backend || img_path) && (img_path_backend !== "" || img_path !== "")) && (
                <img
                    className={"cliccabile"}
                    id={id}
                    src={path}
                    alt=""
                    onClick={openModal}
                />
            )}

            {isModalOpen && (
                <div className="modal-img" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img src={path} alt="" className="modal-image"/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Img;