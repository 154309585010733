import React, {ChangeEvent, useState} from 'react';
import "../styles/Card.css"
import ButtonIcon from "./ButtonIcon";
import {shareButtonClick} from "../utils";

interface ComponentProps {
    url?: string;
}

const ShareSocial: React.FC<ComponentProps> = ({ url = "" }) => {

    return (
        <div className="social-share">
            <a className={"cliccabile fab fa-facebook fa-2x"} href={"https://www.facebook.com/sharer/sharer.php?u=" + url} target="_blank"></a>
            &nbsp;
            <a className={"cliccabile fab fa-x-twitter fa-2x"} href={"https://x.com/intent/tweet?url=" + url} target="_blank"></a>
            &nbsp;
            <a className={"cliccabile fa-solid fa-share fa-2x"} onClick={shareButtonClick}></a>

        </div>
    );
};

export default ShareSocial;