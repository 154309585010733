import React, {useEffect, useState} from 'react';
import {
    addOrRemoveElementToCarousel,
    backend_path, backend_path_slash,
    doRequest, ItineraryFilterOptions, Option, refresh_page,
    ThemeOptions,
    useStateEmail, useStateFile, useStateModal, useStateMultiple, useStateSelect, useStateText, useStateTextArea,
} from "../utils";

import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import {useParams} from "react-router-dom";
import CarouselHead from "../generic_components/CarouselHead";
import "../styles/Carousel.css"
import List from "../generic_components/List";
import Filters from "../generic_components/Filters";
import filters from "../generic_components/Filters";
import ItineraryDetailsViewer from "../specific_components/ItineraryDetailsViewer";
import TextArea from "../generic_components/TextArea";
import SubmitButton from "../generic_components/SubmitButton";
import Label from "../generic_components/Label";
import Modal from "../generic_components/Modal";
import ForumMessageViewer from "../specific_components/ForumMessageViewer";
import TextLetters from "../generic_components/TextLetters";
import ButtonIcon from "../generic_components/ButtonIcon";

interface pageProps {
    title : string
}

const Forum: React.FC<pageProps> = ({title}) => {
    const { itinerary_id } = useParams();
    const [OpenForumData, setOpenForumdata] = useState([]);
    const [ClosedForumData, setClosedForumdata] = useState([]);

    const [TitleForum, setTitleForum] = useStateText();

    useEffect(() => {
        document.title = title

        const fetchData = async () => {
            const response = await doRequest("/Forum/GetForum", {ItineraryId : itinerary_id, Closed : false}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    setOpenForumdata(response.RISULTATO);
                }
            }else{
                console.error("Errore richiesta");
            }

            const response1 = await doRequest("/Forum/GetForum", {ItineraryId : itinerary_id, Closed : true}, true);
            if (response1 && "RISULTATO" in response1 && Array.isArray(response1.RISULTATO)) {
                if(response1.RISULTATO.length > 0) {
                    setClosedForumdata(response1.RISULTATO);
                }
            }else{
                console.error("Errore richiesta");
            }
        };

        fetchData();
    }, []);

    const createNewDiscussion = async () => {
        const response = await doRequest("/Forum/CreateForum", {ItineraryId : itinerary_id, ForumTitle : TitleForum}, true);
        if (response && "RISULTATO" in response) {
            setCorpoModale("Pagina forum creata con successo");
            setTitoloModale("OPERAZIONE ESEGUITA");
            setOnClose(() => refresh_page);
            setTimeout(()=> {
                setMostra(true);
            }, 300);
        }else{
            setCorpoModale("Errore creazione pagina forum");
            setTitoloModale("OPERAZIONE FALLITA");
            setTimeout(()=> {
                setMostra(true);
            }, 300);
        }
    }

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setOnClose] = useStateModal();

    return (
        <div className={"container"}>
            <Modal id={"login_modale"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} setMostra={setMostra}
                   onClose={onClose}/>
            <ItineraryDetailsViewer actions={false} editable={false} feedback_available={false}
                                    itinerary_id={itinerary_id ? parseInt(itinerary_id) : -1}/>
            <br/>
            <div className="row container-fluid py-4 jumbotron-login border"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <div className={"row container-fluid"}>
                    <div className={"col-lg-8"}>
                        <TextLetters label={"CREA NUOVA DISCUSSIONE"} value={TitleForum} onChange={setTitleForum}/>
                    </div>
                    <div className={"col-lg-4"}>
                        <Label label={" "} />
                        <SubmitButton onSubmit={createNewDiscussion} label={"CREA"}/>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row container-fluid py-4 jumbotron-login border"
             style={{borderStyle: "solid", borderRadius: "30px"}}>
            {OpenForumData &&
                <div>
                    <div className={"row"}>
                        <h3>Discussioni aperte</h3>
                    </div>
                    <hr className={"carousel-head-hr"}/>
                    {OpenForumData.length > 0 ?
                        <div>
                            <div className={"row"}>
                                <div className={"col-lg-4"}>
                                    <h5>Titolo</h5>
                                </div>
                                <div className={"col-lg-3"}>
                                    <h5>Utente creatore</h5>
                                </div>
                                <div className={"col-lg-3"}>
                                    <h5>Data creazione</h5>
                                </div>
                            </div>
                            <hr className={"m-2"}/>
                            {OpenForumData.map((element: any, index) => (
                                <div className={"row m-1 py-2"}>
                                    <div className={"col-lg-4"}>
                                        {element.titolo}
                                    </div>
                                    <div className={"col-lg-3"}>
                                        {element.nickname_creatore}
                                    </div>
                                    <div className={"col-lg-3"}>
                                        {element.data_forum}
                                    </div>
                                    <div className={"col-lg-2 d-flex justify-content-center"}>
                                        <ButtonIcon  alt={"Vai alla discussione"} icon={"fa-arrow-right"} size={"1"} operazione={""} onClick={() => {window.location.href = `/ForumMessages/${itinerary_id}/${element.id_forum}`}} />
                                    </div>
                                    <hr className={"hr-tratt"}/>
                                </div>
                            ))
                            }
                        </div>
                        :
                        <h5>Nessuna discussione disponibile</h5>
                    }
                </div>
                }
            </div>
            <br/>
            <div className="row container-fluid py-4 jumbotron-login border"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                {ClosedForumData &&
                    <div>
                        <div className={"row"}>
                            <h3>Discussioni chiuse</h3>
                        </div>
                        <hr className={"carousel-head-hr"}/>
                        {ClosedForumData.length > 0 ?
                            <div>
                                <div className={"row"}>
                                    <div className={"col-lg-4"}>
                                        <h5>Titolo</h5>
                                    </div>
                                    <div className={"col-lg-3"}>
                                        <h5>Utente creatore</h5>
                                    </div>
                                    <div className={"col-lg-3"}>
                                        <h5>Data creazione</h5>
                                    </div>
                                </div>
                                <hr className={"m-2"}/>
                                {ClosedForumData.map((element: any, index) => (
                                    <div className={"row m-1 py-2"}>
                                        <div className={"col-lg-4"}>
                                            {element.titolo}
                                        </div>
                                        <div className={"col-lg-3"}>
                                            {element.nickname_creatore}
                                        </div>
                                        <div className={"col-lg-3"}>
                                            {element.data_forum}
                                        </div>
                                        <div className={"col-lg-2 d-flex justify-content-center"}>
                                            <ButtonIcon  alt={"Vai alla discussione"}icon={"fa-arrow-right"} size={"1"} operazione={""} onClick={() => {window.location.href = `/ForumMessages/${itinerary_id}/${element.id_forum}`}} />
                                        </div>
                                        <hr className={"hr-tratt"}/>
                                    </div>
                                ))
                                }
                            </div>
                            :
                            <h5>Nessuna discussione disponibile</h5>
                        }
                    </div>
                }
            </div>

        </div>
    );
};

export default Forum;