import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    backend_path, backend_path_slash,
    doRequest,
    ThemeOptions,
    useStateEmail, useStateFile, useStateModal,
    useStateNumber, useStateNumberDefault,
    useStateSelect,
    useStateText,
    useStateTextArea
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import Modal from "../generic_components/Modal";
import FileUpload from "../generic_components/FileUpload";
import {useParams} from "react-router-dom";
import Card from "../generic_components/Card";
import DifficultyViewer from "../generic_components/DifficultyViewer";
import Label from "../generic_components/Label";
import Img from "../generic_components/Img";
import ButtonIcon from "../generic_components/ButtonIcon";
import TextLetters from "../generic_components/TextLetters";
import TextArea from "../generic_components/TextArea";
import SubmitButton from "../generic_components/SubmitButton";
import Location from "../generic_components/Location";
import TextTime from "../generic_components/TextTime";
import {wait} from "@testing-library/user-event/dist/utils";

interface ImgProps {
    immagine_1 : string;
    immagine_2 : string;
    immagine_3 : string;
}

interface ComponentProps {
    numero: number;
    itinerary_id : number;
    isCarousel? : boolean;
    currentNumber? : number;
    totalNumber? : number;
}

const EditStops: React.FC<ComponentProps> = ({itinerary_id, numero, isCarousel = false, currentNumber = 1, totalNumber = 1}) => {
    const [ItineraryFound , setItineraryFound] = useState(false);

    const [StopTitle, setStopTitle, setStopTitleDirect] = useStateText();
    const [StopDescription, setStopDescription, setStopDescriptionDirect] = useStateTextArea();
    const [ImageUpload1, setImageUpload1, setImageUpload1Direct] = useStateFile();
    const [ImageUpload2, setImageUpload2, setImageUpload2Direct] = useStateFile();
    const [ImageUpload3, setImageUpload3, setImageUpload3Direct] = useStateFile();
    const [Image1, setImage1] = useState('');
    const [Image2, setImage2] = useState('');
    const [Image3, setImage3] = useState('');
    const [Advice, setAdvice, setAdviceDirect] = useStateTextArea();
    const [StopDuration, setStopDuration] = useState('');

    const [StopLocation, setStopLocation] = useState({});

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra] = useStateModal();

    useEffect(() => {
        const fetchData = async () => {
            const response = await doRequest("/Itinerary/GetStop", {ItineraryId : itinerary_id, Number : numero}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    let stop_data = response.RISULTATO[0];
                    try{
                        setStopTitleDirect(stop_data.titolo);
                        setStopDescriptionDirect(stop_data.descrizione);
                        setAdviceDirect(stop_data.consiglio);
                        setStopDuration(stop_data.durata);

                        try{
                            setImage1(stop_data.immagini.immagine_1);
                            setImage2(stop_data.immagini.immagine_2);
                            setImage3(stop_data.immagini.immagine_3);
                        } catch (ignore) {}

                        try{
                            let loc = stop_data.luogo;
                            setStopLocation(loc);
                        } catch (ignore) {}
                        setItineraryFound(true);
                    }catch (e){
                        console.log(e);
                        setItineraryFound(false);
                    }
                }
            }else{
                console.error("Errore richiesta");
                setItineraryFound(false);
            }
        };

        fetchData();
    }, [itinerary_id, numero]);

    // Altrimenti la mappa dava problemi di inizializzazione
    useEffect(() => {
        const fetchData = async () => {
            if(currentNumber == numero){
                let loc_temp = StopLocation
                setStopLocation({});
                setTimeout(() => {
                    setStopLocation(loc_temp);
                }, 300);
            }
        }

        fetchData().then()
    }, [currentNumber]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        let response = await doRequest("/Itinerary/UpsertStop", {ItineraryId : itinerary_id, Number: numero,
                                                                    StopTitle, StopDescription, Advice,
                                                                ImageUpload1, ImageUpload2, ImageUpload3,
                                                                Image1, Image2, Image3, StopLocation : StopLocation, StopDuration},
                                                            true);
        if(response !== undefined){
            if("RISULTATO" in response){
                setTitoloModale("OPERAZIONE EFFETTUATA");
                setCorpoModale("La tappa è stata aggiornata correttamente")
                setTimeout(()=>{
                    setMostra(true);
                }, 300)
            }else{
                setTitoloModale("ERRORE");
                setCorpoModale("Aggiornamento tappa fallito")
                setTimeout(()=>{
                    setMostra(true);
                }, 300)
            }
        }else{
            setTitoloModale("ERRORE");
            setCorpoModale("Errore di comunicazione col server");
            setTimeout(()=>{
                setMostra(true);
            }, 300)
        }
    };

    return (
        <form role={"form "} key={numero} className={(isCarousel ? `carousel-item panel-${numero}` : "") + (numero == currentNumber ? " active" : "")}>
            <Modal id={`ModalResponse_EditStops${itinerary_id + numero}`} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} />
            <div className={"form-group"}>
                <div className={"row container-fluid"}>
                    <div className={"col-lg-8"}>
                        <TextLetters value={StopTitle} onChange={setStopTitle} label={"Titolo"} obbligatorio={true}/>
                    </div>
                    <div className={"col-lg-4"}>
                        <TextTime value={StopDuration} onChange={setStopDuration} label={"Durata"} obbligatorio={true}/>
                    </div>
                </div>
                <div className="row container-fluid">
                    <TextArea id={"idStopDesc_" + numero} value={StopDescription} onChange={setStopDescription}
                              label={"Descrizione"} obbligatorio={false}/>
                </div>
                <div className={"row container-fluid"}>
                    <div className={"col-lg-8"}>
                        <TextArea id={"idAdvice_" + numero} value={Advice} onChange={setAdvice}
                                  label={"Consigli sullo svolgimento della tappa"} obbligatorio={false}/>
                    </div>
                </div>
                <hr/>
                <div className={"row container-fluid"}>
                    <Label label={"Immagini: "}/>
                </div>
                <div className="row container-fluid">
                    <div className={"col-lg-4"}>
                        <Img id={"image1" + numero} id_source={"idImageUpload_1_div" + numero} param={"width"}
                             img_path_backend={Image1}/>
                        <div id={"idImageUpload_1_div" + numero} className={"col-lg-12"}>
                            <FileUpload id={"idImageUpload_1" + numero} value={ImageUpload1} onChange={setImageUpload1}
                                        label={String.fromCharCode(160)}/>
                        </div>
                    </div>
                    <div className={"col-lg-4"}>
                        <Img id={"image2" + numero} id_source={"image1" + numero} param={"both"}
                             img_path_backend={Image2}/>
                        <div id={"idImageUpload_2_div" + numero} className={"col-lg-12"}>
                            <FileUpload id={"idImageUpload_2"} value={ImageUpload2} onChange={setImageUpload2}
                                        label={String.fromCharCode(160)}/>
                        </div>
                    </div>
                    <div className={"col-lg-4"}>
                        <Img id={"image3" + numero} id_source={"image1" + numero} param={"both"}
                             img_path_backend={Image3}/>
                        <div id={"idImageUpload_3_div" + numero} className={"col-lg-12"}>
                            <FileUpload id={"idImageUpload_3"} value={ImageUpload3} onChange={setImageUpload3}
                                        label={String.fromCharCode(160)}/>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className={"row container-fluid"}>
                    <Location id={"stop_location"} edit={true} Loc={StopLocation} setLocationObject={setStopLocation}/>
                </div>
                <br/>
                <div className={"row container-fluid"}>
                    <SubmitButton label={"AGGIORNA TAPPA"} onSubmit={handleSubmit}/>
                </div>
            </div>
        </form>
    );
};

export default EditStops;