import React, {ChangeEvent, useEffect, useState} from 'react';
import {addOrRemoveElementToCarousel, doRequest, useStateSelect} from "../utils";
import Select from "./Select";
import "../styles/Carousel.css"

interface ComponentProps {
    id?: string;
    item_name : string;
    countEndpoint : string;
    innerItems : any;
    CurrentItemNumber : number;
    setCurrentItemNumber : any;
    ItemCount : number;
    setItemCount : any;
    key_parent : string;
    key_item : string;
    deleteItemEndpoint? : string;
    getSelectOptions? : string;
    selectOptions? : any;
    createItemEndpoint? : string;
    PerPageNumber? : number;
    filters? : {};
}

const Carousel: React.FC<ComponentProps> = ({ id = "IdCarosello" ,selectOptions,filters = {},  PerPageNumber = 10, countEndpoint, item_name, createItemEndpoint, getSelectOptions, deleteItemEndpoint, innerItems, key_parent, key_item, CurrentItemNumber, setCurrentItemNumber, ItemCount, setItemCount}) => {
    const [selectedOption, setSelectedOption] = useStateSelect();

    const selectOnChange = async (e : ChangeEvent<HTMLSelectElement>) => {setSelectedOption(e); setTimeout(() => {document.getElementById("slideTo")?.click();},0); }

    useEffect(() => {
        const fetchData = async () => {
            const response = await doRequest(countEndpoint, {...{[key_parent] : id}, ...{as_pages : true, PerPageNumber}, ...filters}, true);
            if (response && "RISULTATO" in response) {
                setItemCount(parseInt(response.RISULTATO));
            }else{
                console.error("Errore richiesta");
                setItemCount(-1);
            }
        };

        fetchData();

        $('#swipeCarousel').on('slid.bs.carousel', function (event : any) {
            if((event as any).direction == 'right')
                setCurrentItemNumber((event as any).to + 1);
            if((event as any).direction == 'left')
                setCurrentItemNumber((event as any).to + 1);

            let id_select = "selectStops"
            $(`#${id_select}`).selectpicker('destroy').val((event as any).to).selectpicker('render');
        });
    }, [CurrentItemNumber, ItemCount, filters]);

    return (
            <div className={"row container-fluid"}>
                {deleteItemEndpoint ?
                    <div className="col-lg-2 d-flex align-content-center" onClick={async () => {document.getElementById("swipeCarouselPrevious")?.click(); if(await addOrRemoveElementToCarousel(deleteItemEndpoint, {[key_parent] : id, [key_item] : CurrentItemNumber})) {  setTimeout(() => {
                        setItemCount(ItemCount-1);
                    }, 0);}} }>
                        <div className={"row container"}>
                            <i className="fa-solid fa-trash fa-3x" data-bs-target="#swipeCarousel"
                               >
                            </i>
                        </div>
                        <div className={"row container"}>
                            Rimuovi {item_name}
                        </div>
                    </div> : <div className="col-lg-2 d-flex align-content-center" />}
                    <div className="col-lg-1 py-3 d-flex align-content-center justify-content-end">
                        {(ItemCount as number) > 0 &&
                        <i className="fa-solid fa-arrow-left fa-3x" data-bs-target="#swipeCarousel" id={"swipeCarouselPrevious"}
                           data-bs-slide="prev">
                        </i>
                        }
                    </div>
                    <div key={ItemCount} className="col-lg-6 d-flex align-content-center justify-content-center">
                        {(ItemCount as number) > 0 &&
                            <h2 className={"margin-contorno-10"}>
                                {`${item_name} ${CurrentItemNumber} / ${ItemCount}`}
                                <button id="slideTo" className={"d-none"} data-bs-target="#swipeCarousel" data-bs-slide-to={selectedOption} />
                                {(getSelectOptions != undefined) ? <Select href={getSelectOptions} onChange={selectOnChange} label={""} id={"selectStops"} obbligatorio={false} params={{[key_parent] : id}} /> : ( selectOptions !== undefined && selectOptions.length > 0 ? <Select options_override={selectOptions} onChange={selectOnChange} href={""} id={"selectStops"} /> : <div />)}
                            </h2>
                        }
                    </div>
                    <div className="col-lg-1 py-3 d-flex align-content-center">
                        {(ItemCount as number) > 0 &&
                            <i className="fa-solid fa-arrow-right fa-3x" data-bs-target="#swipeCarousel" id={"swipeCarouselNext"}
                               data-bs-slide="next">
                            </i>
                        }
                    </div>
                {createItemEndpoint ?
                    <div className="col-lg-2 d-flex align-content-center" onClick={async () => { if(await addOrRemoveElementToCarousel(createItemEndpoint, {[key_parent] : id, [key_item] : CurrentItemNumber})) {setItemCount(ItemCount+1); setTimeout(() => {
                        document.getElementById("swipeCarouselNext")?.click();
                    }, 0);} }}>
                        <div className={"row container"}>
                            Aggiungi {item_name}
                        </div>
                        <div className={"row container"}>
                            <i className="fa-solid fa-plus-circle fa-3x" data-bs-target="#swipeCarousel"
                               >
                            </i>
                        </div>
                    </div> : <div className="col-lg-2 d-flex align-content-center" />}
                {(ItemCount as number) > 0 ?
                <div>
                    <hr className={"carousel-head-hr"} />
                    <div className="row container-fluid py-0">
                        <div id="swipeCarousel" className="carousel slide" data-bs-interval={false} data-bs-touch="true">
                            <div className={"carousel-inner"}>
                                {innerItems}
                            </div>
                        </div>
                    </div>
                </div> : <div className={"row d-flex py-4"}><h4 className={"d-flex justify-content-center"}>Nessun elemento trovato</h4></div>}
            </div>
    );
};

export default Carousel;