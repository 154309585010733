import React, {ChangeEvent, useEffect, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import Select from "./Select";
import {FilterOption, Option, useStateMultiple, useStateSelect, useStateText} from "../utils";
import Label from "./Label";
import ButtonIcon from "./ButtonIcon";
import Textbox from "./Textbox";
import TextNumber from "./TextNumber";
import TextMinMax from "./TextMinMax";

interface ComponentProps {
    filterOptions : FilterOption[];
    ActiveFilters: any;
    FilterNumber: number;
    FilterSelected: any;
    setFilterNumber: (value: number) => void;
    setFilterSelected: (value: any) => void;
    getStateAtIndex: (index: number) => any;
    addStateAtIndex: (index: number, state: any) => void;
    updateStateAtIndex: (index: number, state: any) => void;
    deleteStateAtIndex: (index: number) => void;
}

const Filters: React.FC<ComponentProps> = ({ filterOptions, ActiveFilters, FilterNumber, FilterSelected, setFilterNumber, setFilterSelected, getStateAtIndex, addStateAtIndex,updateStateAtIndex, deleteStateAtIndex }) => {

    const useAddFilter = () => {
        //console.log(FilterSelected);
        if(FilterSelected){
            let match = filterOptions.filter(currentValue => currentValue.value === FilterSelected);
            if(match[0]?.html_type != ""){
                addStateAtIndex(FilterNumber + 1, { filter : match[0], current_value : "" });
                setFilterNumber(FilterNumber + 1);
            }
            setTimeout(() => {
                console.log(ActiveFilters);
            }, 0)
        }
    }

    useEffect(() => {

    }, []);

    return (
        <div>
            <div className={"row container-fluid d-flex align-items-center"} >
                <div className={"row  py-3"}>
                    <Label label={"Aggiungi filtro ricerca:"} />
                    <div className={"col-lg-2"}>
                        <Select label={""} href={""} options_override={filterOptions} id={"filterOptions"} onChange={setFilterSelected} />
                    </div>
                    <div className={"col-lg-2"}>
                        <ButtonIcon  alt={"Aggiungi filtro"} icon={"fa-plus"} size={"2"} operazione={""} onClick={useAddFilter}/>
                    </div>
                </div>
                {ActiveFilters.length > 0 && ActiveFilters.map((e : any, index : number) => (
                    <div className={"col-lg-" + (["min_max_time"].includes(e.filter.html_type) ? "5" : "3")} key={e.id}>
                        <div className={"row d-flex align-items-center input-group"}>
                            <div className={"col-lg-10"}>
                                {e.filter.html_type == "text" &&
                                    <Textbox label={e.filter.label} value={getStateAtIndex(index).current_value}
                                         onChange={(event) => {
                                             updateStateAtIndex(index, {
                                                 filter: e.filter,
                                                 current_value: event.target.value
                                             });
                                         }}
                                         icon={"fa-x"}
                                         icon_size={"1"}
                                         icon_onclick={() => {
                                             deleteStateAtIndex(index);
                                         }}
                                    />}
                                {e.filter.html_type == "number" &&
                                    <TextNumber label={e.filter.label} value={getStateAtIndex(index).current_value}
                                             onChange={(event) => {
                                                 updateStateAtIndex(index, {
                                                     filter: e.filter,
                                                     current_value: event.target.value
                                                 });
                                             }}
                                             icon={"fa-x"}
                                             icon_size={"1"}
                                             icon_onclick={() => {
                                                 deleteStateAtIndex(index);
                                             }}
                                    />}
                                {e.filter.html_type == "min_max" &&
                                    <TextMinMax label={e.filter.label} value={getStateAtIndex(index).current_value}
                                                onChange={(s) => {
                                                    updateStateAtIndex(index, {
                                                        filter: e.filter,
                                                        current_value: s
                                                    });
                                                }}
                                                icon={"fa-x"}
                                                icon_size={"1"}
                                                icon_onclick={() => {
                                                    deleteStateAtIndex(index);
                                                }}
                                    />}
                                {e.filter.html_type == "min_max_time" &&
                                    <TextMinMax label={e.filter.label} value={getStateAtIndex(index).current_value}
                                                type={"time"}
                                                onChange={(s) => {
                                                    updateStateAtIndex(index, {
                                                        filter: e.filter,
                                                        current_value: s
                                                    });
                                                }}
                                                icon={"fa-x"}
                                                icon_size={"1"}
                                                icon_onclick={() => {
                                                    deleteStateAtIndex(index);
                                                }}
                                    />}
                                {e.filter.html_type == "select" &&
                                    <Select label={e.filter.label} href={e.filter.html_href} id={"sel" + index} onChange={(event) => {
                                        updateStateAtIndex(index, {
                                            filter: e.filter,
                                            current_value: event.target.value
                                        })}}
                                        icon={"fa-x"}
                                        icon_size={"1"}
                                        icon_onclick={() => {
                                            deleteStateAtIndex(index);
                                        }}
                                    />}
                            </div>
                        </div>
                        <div className={"col-lg-3 cursor-pointer"}>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Filters;