import React from 'react';
import "../styles/SubmitButton.css"

interface SubmitButtonProps {
    label?: string;
    onSubmit: (e: React.FormEvent) => void | Promise<void>;
    disabled?: boolean;
    width?: string;
    centered?: boolean;
    color? : string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ label, onSubmit, disabled = false, width = "100%", centered = false, color}) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onSubmit(event);
    };

    return (
        <div style={{position: "relative"}}>
            <button
                type="button"
                className={"btn btn-secondary " + color}
                onClick={handleClick}
                disabled={disabled}
                style={{width : "100%"}}
            >
                {label}
            </button>
        </div>
    );
};

export default SubmitButton;