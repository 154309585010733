import React, {useState} from "react";
import axios from "axios";
import {wait} from "@testing-library/user-event/dist/utils";
import _lodash from "lodash";

export const backend_path = "https://tiwbackend.sb.cloudns.ph"
export const backend_path_slash = backend_path + "/"

export interface Option {
    value: string;
    label?: string;
    subtext: string;
    disabled?: boolean;
}

export interface FilterOption {
    value: string;
    label?: string;
    subtext: string;
    disabled?: boolean;
    html_type: any;
}

export function useStateEmail() {
    let [text, setEmail] = useState('');
    let handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    let [errore, setErrore] = useState('');
    return [text, handleEmailChange, setEmail, errore, setErrore,] as const;
}

export function useStateText() {
    let [text, setText] = useState('');
    let handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };
    let [errore, setErrore] = useState('');
    return [text, handleTextChange, setText, errore, setErrore,] as const;
}

export function useStateFile() {
    let [file, setFile] = useState<File | null>(null);
    let [error, setError] = useState<string>('');

    let handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = event.target.files?.[0] || null;
        setFile(uploadedFile);
    };

    return [file, handleFileChange, setFile, error, setError,] as const;
}

export function useStateTextArea() {
    let [text, setText] = useState('');
    let handleTextChange = (event: React.ChangeEvent<HTMLDivElement>) => {
        setText(event.currentTarget.innerHTML || '');
    };
    let [errore, setErrore] = useState('');
    return [text, handleTextChange, setText, errore, setErrore,] as const;
}

export function useStateMultiple() : any {
    let [ActiveFilters, setActiveFilters] = useState<any[]>([]);
    let [FilterNumber, setFilterNumber] = useState(0);
    let [FilterSelected, setFilterSelected] = useStateSelect();

    let updateStateAtIndex = (index : number, newValue : any) => {
        setActiveFilters((prevStates : any) => {
            const updatedStates = [...prevStates];
            updatedStates[index] = newValue;
            return updatedStates;
        });
    };

    let getStateAtIndex = (index : number) => {
        if (index >= 0 && index < ActiveFilters.length) {
            return ActiveFilters[index];
        } else {
            console.error("Index out of bounds");
            return undefined;
        }
    };

    let deleteStateAtIndex = (index : number) : any => {
        setActiveFilters((prevFilters) => {
            if (index >= 0 && index < prevFilters.length) {
                const updatedFilters = [...prevFilters];
                updatedFilters.splice(index, 1); // Keep `ActiveFilters` in sync
                return updatedFilters;
            } else {
                console.error(`Index ${index} out of bounds for ActiveFilters`);
                return prevFilters;
            }
        });
    };

    let addStateAtIndex = (index: number, newState: any) => {
        setActiveFilters((prevFilters) => {
            const updatedFilters = [...prevFilters];
            updatedFilters.splice(index, 0, newState);
            return updatedFilters;
        });
    };

    let getFiltersValues = () => {
        return ActiveFilters.reduce((res, elem) => {
            const key = elem.filter.value;
            const value = elem.current_value;

            if (res[key]) {
                res[key] += `&&%${value}%`;
            } else {
                res[key] = `%${value}%`;
            }
            return res;
        }, {});
    };

    return [ActiveFilters, FilterNumber, FilterSelected, setFilterNumber, setFilterSelected, getStateAtIndex, addStateAtIndex, updateStateAtIndex, deleteStateAtIndex, getFiltersValues];
}

export function useStateSelect() {
    let [selectedValue, setSelectedValue] = useState<string | null>(null);
    let handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value);
    };
    let [errore, setErrore] = useState('');
    let [options, setOptions] = useState<Option[]>([]);
    return [selectedValue, handleChange, setSelectedValue, options, setOptions, errore, setErrore] as const;
}

export function usePhoneNumberSelect() {
    let [phoneNumber, setValue] = useState('');
    let handleChange = (phone: any) => {
        setValue(phone);
    };
    let [errore, setErrore] = useState('');
    return [phoneNumber, handleChange, setValue, errore, setErrore,] as const;
}

export function useStateNumber() {
    let [numberOrNull, setNumberOrNull] = useState<number | null>(null);

    let handleChange = (num: any) => {
        setNumberOrNull(num);
    };
    let [errore, setErrore] = useState('');
    return [numberOrNull, handleChange, setNumberOrNull, errore, setErrore] as const;

}

export function useStateNumberDefault(num_default: number) {
    let [numberOrNull, setNumberOrNull] = useState<number>(num_default);

    let handleChange = (num: any) => {
        setNumberOrNull(num);
    };
    let [errore, setErrore] = useState('');
    return [numberOrNull, handleChange, setNumberOrNull, errore, setErrore] as const;

}

export function useStateModal() {
    let [text, setText] = useState('');
    let [title, setTitle] = useState('');
    let [mostra, setMostra] = useState(false);
    const [onClose, setOnClose] = useState<() => void>(() => {
    });
    return [text, setText, title, setTitle, mostra, setMostra, onClose, setOnClose] as const;
}

export async function setFormError(errore: any, key: string, func: any) {
    await func("")
    if (key in errore)
        await func(errore[key])
}

export async function handleErrorCheck(field: string, value: any, setErrore: any, err: string, errore: string, defaultErrore: string) {
    let response = await doRequest("/ValidateField/" + field, {"value": value}, false);
    if (response == undefined) {
        setErrore("Controllo campo mancante")
        return
    }
    if ("ERRORE_VALIDAZIONE" in response) {
        if (err == "")
            setErrore("");
        if (errore != "")
            setErrore(errore);
        else
            setErrore(defaultErrore);
    } else
        setErrore("");
}

const config = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export async function checkToken() {
    let token = localStorage.getItem("TOKEN");
    if (token == null)
        return false;

    let response = await doRequest("/NotLoggedUser/CheckToken", {token}, false);
    if (response == undefined)
        return false;

    return "RISULTATO" in response;
}

export async function doRequest(path: any, params: any, token_request: any) {
    try {
        if (token_request === true) {
            params.token = localStorage.getItem("TOKEN");
        }
        const response = await axios.post(backend_path + path,
            params, config
        );
        //console.log('Response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export const ThemeOptions = [
    {value: "MAR", label: "MARE", subtext: ""},
    {value: "MON", label: "MONTAGNA", subtext: ""},
    {value: "CIT", label: "CITTÀ", subtext: ""},
    {value: "ENO", label: "ENOGASTRONOMIA", subtext: ""},
    {value: "CUL", label: "CULTURA E STORIA", subtext: ""},
    {value: "REL", label: "RELAX E BENESSERE", subtext: ""},
]

export function capFirstLetter(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export async function addOrRemoveElementToCarousel(endpoint: string, params: {}) {
    const response = await doRequest(endpoint, params, true);
    return !!(response && "RISULTATO" in response);
}

export function objEquals(obj1 : object, obj2 : object) {
    return _lodash.isEqual(obj1, obj2);
}

export function extractTextFromHTML(htmlString: string) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
}

export function truncateText(text:string , maxLength = 150) {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '   ...';
    }
    return text;
}

export function areAllKeysNullOrEmpty(obj : object) {
    return Object.values(obj).every(value => value === "null" || value === "");
}

export function truncateHtmlText(html: string, maxLength = 150): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const visibleText = tempDiv.textContent || tempDiv.innerText || '';
    if (visibleText.length > maxLength) {
        return visibleText.slice(0, maxLength) + '...';
    }
    return visibleText;
}

export function removeColorsFromHTML(htmlString : string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    const elements = tempDiv.querySelectorAll('*[style]');
    elements.forEach((element : any) => {
        const style = element.style;
        if (style.backgroundColor) {
            style.backgroundColor = '';
        }
        if (style.color) {
            style.color = '';
        }
    });
    return tempDiv.innerHTML;
}

export async function shareButtonClick(){
    if (navigator.share) {
        try {
            await navigator.share({
                title: document.title,
                text: '',
                url: window.location.href,
            });
        } catch (error) {
            console.error('Errore durante la condivisione:', error);
        }
    } else {
        alert('La condivisione non è supportata su questo dispositivo.');
    }
}

export const ItineraryFilterOptions = [
    { value: "", label: "", subtext: "Seleziona..." , html_type : "" },
    { value: "FullText", label: "Ricerca libera", subtext: "" , html_type : "text" },
    { value: "ItineraryTitle", label: "Titolo", subtext: "", html_type : "text"  },
    { value: "ItineraryDescription", label: "Descrizione", subtext: "", html_type : "text"  },
    { value: "Tema", label: "Tema", subtext: "", html_type : "select", html_href : "/GetConstants/ItineraryThemeOptions"  },
    { value: "Difficulty", label: "Difficoltà", subtext: "" , html_type : "min_max" },
    { value: "Duration", label: "Durata", subtext: "" , html_type : "min_max_time" },
    { value: "Location", label: "Luogo", subtext: "" , html_type : "text" },
];

export const FeedbackFilterOptions = [
    { value: "", label: "", subtext: "Seleziona..." , html_type : "" },
    //{ value: "FullText", label: "Ricerca libera", subtext: "" , html_type : "text" },
    { value: "FeedbackTitle", label: "Titolo", subtext: "", html_type : "text"  },
    { value: "FeedbackDescription", label: "Descrizione", subtext: "", html_type : "text"  },
    { value: "FeedbackStars", label: "Punteggio", subtext: "" , html_type : "number" }
];

export const to_home = () => {window.location.href = "/"};
export const to_logout = () => {window.location.href = "/Logout"};
export const refresh_page = () => {window.location.href = window.location.href + ""}