import React, {useEffect, useState} from 'react';
import {addOrRemoveElementToCarousel, doRequest, useStateSelect} from "../utils";
import Select from "./Select";
import ItineraryDetailsForList from "../specific_components/ItineraryDetailsForList";
import ButtonIcon from "./ButtonIcon";
import FeedbackViewer from "./FeedbackViewer";

interface ComponentProps {
    id: string;
    isCarousel?: boolean;
    CurrentPage : number;
    PerPageNumber : number;
    endpoint : string;
    filters : {};
    editable? : boolean;
}

const List: React.FC<ComponentProps> = ({ id,isCarousel = false, editable = false, CurrentPage, PerPageNumber, endpoint, filters }) => {
    const [Items, setItems] = useState<object[]>([]);

    useEffect(() => {
        //console.log(filters)
        const fetchData = async () => {
            const response = await doRequest(endpoint, {...{CurrentPage, PerPageNumber}, ...filters}, true);
            if (response && "RISULTATO" in response) {
                setItems(response.RISULTATO);
            }else{
                console.error("Errore richiesta");
                setItems([]);
            }
        };

        fetchData();
    }, [PerPageNumber, filters]);

    return (
        <div className={(isCarousel ? `carousel-item` : "") + (((Items && Items.length > 0 && "numero_riga" in Items[0]) ? Items[0].numero_riga == CurrentPage : false) ? " active" : "")}>
            {(Items && Items.length > 0) ? Items.map((item : any, index) => (
                <div key={index} className={"row"}>
                    {
                        endpoint.includes("GetItinerary")
                            ?
                            <ItineraryDetailsForList id={"id" in item ? item.id as string : ""} editable={editable} itinerary_id={"id" in item ? item.id as number : 0} />
                            :
                            <FeedbackViewer itinerary_id={parseInt(item.id)} Feedback={item} />
                    }
                    <hr className={"hr-list margin-contorno-25"} />
                </div>
                )) :
                ""
            }
        </div>
    );
};

export default List;