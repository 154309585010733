import React, {ChangeEvent, useEffect, useState} from 'react';

interface ComponentProps {
    id: string;
    corpo: string;
    titolo: string;
    mostra?: boolean;
    setMostra?: (a : boolean) => void;
    onClose?: () => void;
}

const Modal: React.FC<ComponentProps> = ({ id, corpo, titolo, mostra = false, setMostra = (a : boolean) => {}, onClose }) => {

    useEffect(() => {
        if(mostra)
            document.getElementById(id + "_button")?.click();
    }, [mostra]);

    // Posso impostare qualcosa al momento della chiusura della modale
    const myModal = document.getElementById(id);
    myModal?.addEventListener('hidden.bs.modal', function () {
        setMostra(false);
        if(onClose !== undefined)
            onClose();
    });

    return (
        <div>
            <button id={id + "_button"} type="button" style={{display:"none"}} className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#" + id}>

            </button>
            <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={"#" + id}
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">{titolo}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {corpo}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;