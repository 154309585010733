import React, {useEffect, useState} from 'react';
import {
    backend_path_slash,
    doRequest,
    extractTextFromHTML, truncateText,
    useStateEmail,
    useStateSelect,
    useStateText
} from "../utils";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import "../styles/Jumbotron.css"
import SubmitButton from "../generic_components/SubmitButton";
import "../styles/GenericLink.css"
import Card from "../generic_components/Card";
import ButtonIcon from "../generic_components/ButtonIcon";
import Carousel from "../generic_components/Carousel";
import CircleStars from "../generic_components/CircleStars";

interface pageProps {
    title : string
}

const Home: React.FC<pageProps> = ({title}) => {
    const [Suggesteditineraries, setSuggesteditineraries] = useState([]);
    const [Bestitineraries, setBestitineraries] = useState([]);
    const [AvgItineraries, setAvgItineraries] = useState(0);
    const [Nickname, setNickname] = useState('');

    useEffect(() => {
        document.title = title

        const fetchData = async () => {
            const response = await doRequest("/Itinerary/GetItineraryFromInterests", {}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    setSuggesteditineraries(response.RISULTATO);
                }
            }else{
                console.error("Errore richiesta 1");
            }
        };

        const fetchData1 = async () => {
            const response = await doRequest("/Itinerary/GetItinerary/Owner", {PerPageNumber : 3}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    let elements : any = []
                    response.RISULTATO.map((element : any)=>{
                        if(element.copertina != "" && element.copertina != null && element.copertina != "None"){
                            elements.push({img : backend_path_slash + element.copertina, title : element.titolo, description : truncateText(extractTextFromHTML(element.descrizione))});
                        }
                    })
                    setBestitineraries(elements);
                }
            }else{
                console.error("Errore richiesta 2");
            }
        };

        const fetchData2 = async () => {
            const response = await doRequest("/User/GetInfo/Owner", {}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length == 1) {
                    setAvgItineraries(response.RISULTATO[0].media_itinerari);
                    setNickname(response.RISULTATO[0].nickname);
                }
            }else{
                console.error("Errore richiesta 2");
            }
        };

        fetchData().then(fetchData1).then(fetchData2);
    }, []);

    return (
        <div className="container">
            <div className="row d-flex justify-content-between py-4">
                    <div className="col-lg-4">
                        <ButtonIcon icon={"fa-magnifying-glass"} size={"3"} operazione={"Cerca un itinerario"}
                                    href={"/SearchItinerary"}/>
                    </div>
                    <div className="col-lg-4">
                        <ButtonIcon icon={"fa-plus"} size={"3"} operazione={"Crea un itinerario"}
                                    href={"/CreateItinerary"}/>
                    </div>
                    <div className="col-lg-4">
                        <ButtonIcon icon={"fa-sliders"} size={"3"} operazione={"Gestisci itinerari"}
                                    href={"/YourItineraries"}/>
                    </div>
                </div>
                <div className="row container-fluid py-4 jumbotron-login border min-vh-10"
                     style={{borderStyle: "solid", borderRadius: "30px"}}>
                    <div className="row container-fluid">
                        <h3 className={""}>
                            Itinerari suggeriti
                        </h3>
                        {Suggesteditineraries && Suggesteditineraries.length > 0 ?
                            <div className="col-md-12">
                                <div className="row container-fluid d-flex justify-content-between">
                                    {Suggesteditineraries.map((element: any, index) => (
                                        <Card href={`/ViewStops/${element.id}`}
                                              img={backend_path_slash + element.copertina}
                                              title={element.titolo}
                                              md={"4"}
                                              description={truncateText(extractTextFromHTML(element.descrizione))}/>
                                    ))}
                                </div>
                            </div>
                            :
                            <h5>Nessun elemento disponibile, modifica i tuoi interessi nella configurazione <a href={"/MyAccount"}>account</a></h5>
                        }
                    </div>
                </div>
                <div className={"row container-fluid py-3"}></div>
                <div className="row container-fluid jumbotron-login border min-vh-10"
                     style={{borderStyle: "solid", borderRadius: "30px"}}>
                    <div className="row container-fluid py-4">
                        <h3 className={""}>
                            I tuoi itinerari migliori
                        </h3>
                        <div className="row">
                            <div className="col-md-8">
                                <Carousel elements={Bestitineraries}/>
                            </div>
                            <div className="col-md-4 align-content-center">
                                <CircleStars id={"ValItinerari"} title={"Media valutazione dei tuoi itinerari"}
                                             value={AvgItineraries} max={5}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
            };

            export default Home;