import React, {useEffect, useState} from "react";
import "../styles/DifficoltySelector.css";
import {hover} from "@testing-library/user-event/dist/hover";
import Label from "./Label";

interface ComponentProps {
    length: number;
    value: number | 1;
    setValue: (num: any) => void;
    label?: string;
}

const DifficultyViewer: React.FC<ComponentProps> = ({ length, value, setValue, label }) => {
    const [hoverIndex, setHoverIndex] = useState<number>(value);

    useEffect(() => {
    }, []);

    return (
        <div>
            <Label label={label} />
            <div className="diff-container" onMouseLeave={() => setHoverIndex(value)}>

                {Array.from({length: length}).map((_, index) => (
                    <div
                        className={"rectangle color-" + ((value >= index + 1) ? String(index + 1) : "transparent")}
                        key={index + 1}
                        style={{width: `${100 / length}%`}}
                    >
                        <center>{(value == index + 1) ? String(value) : '\u00A0'}</center>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DifficultyViewer;