import React, { ChangeEvent } from 'react';
import ErrorLabel from "./ErrorLabel";
import Label from "./Label";

interface TextboxProps {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    icon?: string;
    icon_size?: string;
    icon_onclick?: () => void;
    onBlur?: () => void;
}

const Textbox: React.FC<TextboxProps> = ({ value, onChange,onBlur, label, placeholder = '...', obbligatorio = false, errore = "", icon, icon_size, icon_onclick }) => {
    return (
        <div style={{ marginBottom: '16px' }}>
            <Label label={label + (obbligatorio ? " (*)" : "")} />
            <div className={"input-group"}>
                <input
                    type="text"
                    id="text"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={"form-control"}
                    onBlur={onBlur}
                />
                {icon && <span className={"input-group-text cliccabile"} onClick={icon_onclick}><i className={"fa-solid " + icon + " fa-" + icon_size + "x"}/></span>}
            </div>
            <ErrorLabel label={errore}/>
        </div>
    );
};

export default Textbox;