import React, {useEffect, useState} from 'react';
import {
    backend_path, backend_path_slash,
    doRequest, refresh_page, removeColorsFromHTML,
    ThemeOptions, to_home,
    useStateEmail, useStateFile, useStateModal,
    useStateNumber, useStateNumberDefault,
    useStateSelect,
    useStateText,
    useStateTextArea
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import DifficultyViewer from "../generic_components/DifficultyViewer";
import Label from "../generic_components/Label";
import Img from "../generic_components/Img";
import ButtonIcon from "../generic_components/ButtonIcon";
import Modal from "../generic_components/Modal";
import TextLetters from "../generic_components/TextLetters";
import Feedback from "../generic_components/Feedback";
import CircleStars from "../generic_components/CircleStars";
import ShareSocial from "../generic_components/ShareSocial";

interface pageProps {
    itinerary_id : number;
    editable? : boolean;
    feedback_available?: boolean;
    actions?: boolean;
}

const ItineraryDetailsViewer: React.FC<pageProps> = ({itinerary_id,actions = true, editable = true, feedback_available = true}) => {
    const [ItineraryFound , setItineraryFound] = useState(false);

    const [ItineraryTitle, setItineraryTitle] = useState('');
    const [ItineraryDescription, setItineraryDescription] = useState('');
    const [Tema, setTema] = useState('');
    const [Difficulty, setDifficulty] = useState<number | null>(null);
    const [CoverImagePath, setCoverImagePath] = useState('');
    const [AvgItinerary, setAvgItinerary] = useState<number>(-1);
    const [Published, setPublished] = useState<boolean>();

    const [CreatorId, setCreatorId] = useState<number>();

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setModalOnClose] = useStateModal();


    useEffect(() => {
        const fetchData = async () => {
            const response = await doRequest("/Itinerary/GetItinerary", {id : itinerary_id}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                let dati_itinerario = response.RISULTATO[0];
                console.log(dati_itinerario.titolo);
                try{
                    setItineraryTitle(dati_itinerario.titolo);
                    setItineraryDescription(dati_itinerario.descrizione);
                    setTema(dati_itinerario.tema);
                    setDifficulty(dati_itinerario.difficolta);
                    setCoverImagePath(dati_itinerario.copertina);
                    setPublished(dati_itinerario.pubblicato);
                    setCreatorId(dati_itinerario.id_utente);
                    if(dati_itinerario.media_punteggio !== null)
                        setAvgItinerary(dati_itinerario.media_punteggio);
                    else
                        setAvgItinerary(0);

                    setItineraryFound(true);
                }catch (e){
                    console.log(e);
                    setItineraryFound(false);
                }
            }else{
                console.error("Errore richiesta");
                setItineraryFound(false);
            }
        };

        fetchData();
    }, [itinerary_id]);

    const deleteElement = async () => {
        const response = await doRequest("/Itinerary/DeleteItinerary", {id : itinerary_id}, true);
        if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
            setCorpoModale("Elemento rimosso con successo");
            setTitoloModale("OPERAZIONE EFFETTUATA");
            setModalOnClose(() => to_home);
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }else{
            setCorpoModale("Errore in rimozione elemento");
            setTitoloModale("OPERAZIONE FALLITA");
            setTimeout(() => {
                setMostra(true);
            }, 300)

            setItineraryFound(false);
        }
    }

    const publishOrUnpublishItinerary = async (pubblica : string) => {
        const response = await doRequest(`/Itinerary/PublishItinerary/${pubblica}`, {ItineraryId : itinerary_id}, true);
        if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
            setCorpoModale("La pubblicazione è stata gestita");
            setTitoloModale("OPERAZIONE EFFETTUATA");
            setModalOnClose(() => refresh_page);
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }else{
            setCorpoModale("Errore nella gestione della pubblicazione");
            setTitoloModale("OPERAZIONE FALLITA");
            setTimeout(() => {
                setMostra(true);
            }, 300)

            setItineraryFound(false);
        }
    }

    return (
        <div>
            <Modal id={"ModalResponse"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} onClose={onClose} />
            <div className="row container-fluid py-4 jumbotron-login border"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <div className="row container-fluid">
                    <div className="col-lg-6">
                        <a className="" data-bs-toggle="collapse" href="#itinerary_details" role="button">
                            <h2 className={" margin-contorno-10"}>
                                Info itinerario: {ItineraryTitle}
                            </h2>
                        </a>
                    </div>
                    {feedback_available &&
                        <div className="col-lg-6 d-flex justify-content-end align-items-center">
                            <a className="" data-bs-toggle="collapse" href="#user_feedback" role="button">
                                <h5 className={" margin-contorno-10"}>
                                    Lascia un feedback!
                                </h5>
                            </a>
                            Oppure condividi &nbsp;<ShareSocial url={window.location.href}/>
                        </div>
                    }
                </div>

                {/* Sezione collapse sinistra */}
                <div className="row container-fluid collapse" id={"itinerary_details"}>
                    <div id={"itintextdata"} className={"col-lg-5"}>
                    <hr/>
                        <Label label={"Descrizione: "}/>
                        <div dangerouslySetInnerHTML={{__html: removeColorsFromHTML(ItineraryDescription)}}/>
                        <hr/>
                        <div className={"col-lg-6"}>
                            <DifficultyViewer length={10} value={Difficulty !== null ? Difficulty : 0} setValue={() => {
                            }} label={"Difficoltà: "}/>
                        </div>
                        <hr/>
                    </div>
                    <div key={AvgItinerary} className={"row col-lg-3 col-md-12 d-flex container-fluid"}>
                        <CircleStars href_dettaglio={`/ViewFeedback/${itinerary_id}` } id={"ValItinerari"} title={"Media valutazione"} value={AvgItinerary} max={5}/>
                    </div>
                    <div className={"col-lg-4"}>
                        <center>
                                <Img id={"coverImage"} value_override={"100%"} param={"width"} img_path_backend={CoverImagePath}/>
                            {actions &&
                                <div className={"py-4"}>
                                    <ButtonIcon font_size={"15px"} icon={"fa-comment"} size={"2"} operazione={"Forum"} onClick={() => {window.location.href = `/Forum/${itinerary_id}`}} />
                                    <hr/>
                                    <ButtonIcon font_size={"15px"} icon={"fa-user"} size={"2"} operazione={"Visualizza creatore itinerario"} onClick={() => {window.location.href = `/AccountView/${CreatorId}`}} />
                                </div>

                            }
                        </center>
                    </div>
                    {editable &&
                        <div className={"row d-flex"}>
                            <div className={"col-lg-4"}>
                                <ButtonIcon font_size={"18px"} icon={"fa-pencil"} size={"2"} operazione={"Modifica itinerario"} onClick={() => {window.location.href = `/EditItinerary/${itinerary_id}`}}/>
                            </div>
                            <div className={"col-lg-4"}>
                                {Published ?
                                    <ButtonIcon font_size={"18px"} icon={"fa-share-nodes"} icon_stack={"fa-slash"} size={"2"} operazione={"Rimuovi pubblicazione itinerario"} onClick={() => {publishOrUnpublishItinerary("false")}}/>
                                    :
                                    <ButtonIcon font_size={"18px"} icon={"fa-share-nodes"} size={"2"} operazione={"Pubblica itinerario"} onClick={() => {publishOrUnpublishItinerary("true")}}/>
                                }
                            </div>
                            <div className={"col-lg-4"}>
                                <ButtonIcon font_size={"18px"} icon={"fa-trash"} size={"2"} operazione={"Elimina itinerario"} onClick={deleteElement}/>
                            </div>
                        </div>
                    }
                </div>

                {/* Sezione collapse sinistra */}
                <div className="row container-fluid collapse" id={"user_feedback"}>
                    <Feedback itinerary_id={itinerary_id} />
                </div>
            </div>
        </div>
    );
};

export default ItineraryDetailsViewer;