import React, {useEffect, useState} from 'react';
import {
    doRequest,
    ThemeOptions, to_home, usePhoneNumberSelect,
    useStateEmail, useStateFile, useStateModal,
    useStateNumber, useStateNumberDefault,
    useStateSelect,
    useStateText,
    useStateTextArea
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import TextLetters from "../generic_components/TextLetters";
import TextArea from "../generic_components/TextArea";
import Select from "../generic_components/Select";
import {wait} from "@testing-library/user-event/dist/utils";
import NumberGradientSelector from "../generic_components/NumberGradientSelector";
import ButtonIcon from "../generic_components/ButtonIcon";
import SubmitButton from "../generic_components/SubmitButton";
import Modal from "../generic_components/Modal";
import FileUpload from "../generic_components/FileUpload";
import {usePhoneInput} from "react-international-phone";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import TextAlphaNumeric from "../generic_components/TextAlphaNumeric";
import PhoneNumber from "../generic_components/PhoneNumber";
import List from "../generic_components/List";
import Img from "../generic_components/Img";
import Label from "../generic_components/Label";
import Textbox from "../generic_components/Textbox";

interface pageProps {
    title : string
}

const AccountEdit: React.FC<pageProps> = ({title}) => {
    useEffect(() => {
        document.title = title
    }, []);

    const [UserId, setUserId] = useState<number>();
    const [nickname, handleNickChange, setNicknameDirect] = useStateText();
    const [email, handleEmailChange, setEmailDirect] = useStateEmail();
    const [nominativo, handleNominativoChange, setNominativoDirect] = useStateText();
    const [numero, handleNumeroChange, setNumeroDirect] = usePhoneNumberSelect();
    const [badge, setBadge] = useState<boolean>();
    const [badgeItinerari, setBadgeItinerari] = useState<boolean>();
    const [interessi, handleInteressiChange, setInteressiDirect] = useStateText();

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra] = useStateModal()

    useEffect(() => {
        const fetchData = async () => {
            const response = await doRequest("/User/GetInfo/Owner", {}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    let account_data = response.RISULTATO[0];
                    try{
                        setUserId(account_data.id);
                        setNicknameDirect(account_data.nickname);
                        setEmailDirect(account_data.email);
                        setNominativoDirect(account_data.nominativo);
                        setNumeroDirect(account_data.numero_di_telefono);
                        setBadge(account_data.badge);
                        setBadgeItinerari(account_data.badge_itinerari);
                        setInteressiDirect(account_data.interessi);
                    }catch (e){
                        console.log(e);
                    }
                }
            }else{
                console.error("Errore richiesta");
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async () => {
        setMostra(false);
        let response = await doRequest("/User/EditUser", {nickname, email, nominativo, numero, interessi}, true);
        if(response !== undefined && response !== null){
            if("RISULTATO" in response){
                setCorpoModale("Il profilo è stato modificato");
                setTitoloModale("OPERAZIONE EFFETTUATA");
                setMostra(true);
            }
            if("ERRORE" in response){
                setCorpoModale("Errore nella modifica del profilo");
                setTitoloModale("OPERAZIONE FALLITA");
                setMostra(true);
            }
        }else{
            setCorpoModale("Impossibile comunicare col server");
            setTitoloModale("OPERAZIONE FALLITA");
        }

    };

    return (
        <div className="row d-flex justify-content-center py-4">
            <Modal id={"ModalResponse"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra}/>
            <div className="row container-fluid jumbotron-signup jumbotron col-lg-8 border py-4"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <div className="row container-fluid py-4">
                    <div className="col-lg-12">
                        <h1 className="text-center">
                            MODIFICA INFORMAZIONI ACCOUNT
                        </h1>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={"col-lg-8"}/>
                    <div className="col-lg-8">
                        <div className="col-lg-12">
                            <form role="form">
                                <div className="form-group">
                                    <div className={"row container-fluid"}>
                                        <Email value={email} onChange={handleEmailChange} label="E-mail"
                                               placeholder="tuamail@dominio.com" obbligatorio={true}/>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <TextAlphaNumeric value={nickname} onChange={handleNickChange} label="Nickname"
                                                          placeholder="" obbligatorio={true}/>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <div className={"col-lg-6"}>
                                            <TextLetters value={nominativo} onChange={handleNominativoChange}
                                                         label="Nominativo" placeholder="" obbligatorio={true}/>
                                        </div>
                                        <div className={"col-lg-6"}>
                                            <PhoneNumber id={"phonenumber"} value={numero} onChange={handleNumeroChange}
                                                         label={"Numero di telefono"} obbligatorio={true}/>
                                        </div>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <div className={"col-lg-12"}>
                                            <Textbox value={interessi} onChange={handleInteressiChange}
                                                         label="Interessi (inserisci parole chiave per poterti dare suggerimenti sui contenuti)" placeholder="" obbligatorio={false}/>
                                        </div>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <div className={"col-lg-8"}>
                                            <SubmitButton color={"azzurro"} label={"AGGIORNA INFO"}
                                                          onSubmit={handleSubmit}/>
                                        </div>
                                        <div className={"col-lg-4"}>
                                            <SubmitButton color={"rosso"} label={"Reset password"} onSubmit={() => {
                                                window.location.href = "/Resetpassword"
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row container-fluid py-3"></div>
            <div className={"row container-fluid jumbotron-login col-lg-8 border py-4"}
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <div className="row py-1">
                    <div className={"d-flex justify-content-between"}>
                        <a className="" data-bs-toggle="collapse" href="#itineraries" role="button">
                            <h4>Itinerari con valutazione più alta</h4>
                        </a>
                        <a className="" data-bs-toggle="collapse" href="#badges" role="button">
                            <h4>Badge ottenute</h4>
                        </a>
                    </div>
                </div>
                <div id={"itineraries"} className="row collapse ">
                    <List id={`itinerary_list`}
                          isCarousel={true}
                          CurrentPage={1}
                          PerPageNumber={3}
                          endpoint={"/Itinerary/GetItinerary"}
                          editable={false}
                          filters={{UserId : UserId}}
                    />
                </div>
                <div id={"badges"} className="row collapse ">
                    {(badge || badgeItinerari)
                        ?
                        <center>
                            {badge &&
                                <div>
                                    <Img id={"badge_img"} img_path={"/badge_follower.jpeg"} value_override={"200px"} param={"width"}/>
                                </div>
                            }
                            {badgeItinerari &&
                                <div>
                                    <Img id={"badge_itinerari_img"} img_path={"/badge_itinerari.jpeg"} value_override={"200px"} param={"width"}/>
                                </div>
                            }
                        </center>
                        :
                        <h5>Nessuna badge disponibile</h5>
                    }
                </div>
            </div>
        </div>
    );
};

export default AccountEdit;