import React, {useEffect, useState} from 'react';
import {
    backend_path, backend_path_slash,
    doRequest, refresh_page,
    ThemeOptions, to_home,
    useStateEmail, useStateFile, useStateModal,
    useStateNumber, useStateNumberDefault,
    useStateSelect,
    useStateText,
    useStateTextArea
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import DifficultyViewer from "../generic_components/DifficultyViewer";
import Label from "../generic_components/Label";
import Img from "../generic_components/Img";
import ButtonIcon from "../generic_components/ButtonIcon";
import Modal from "../generic_components/Modal";
import "../styles/ForumMessage.css"

interface ComponentProps {
    testo : string;
    nickname : string;
    data_creazione: string;
    owner: boolean;
}

const ForumMessageViewer: React.FC<ComponentProps> = ({testo, data_creazione, nickname, owner}) => {
    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setModalOnClose] = useStateModal();


    useEffect(() => {

    }, []);

    return (
        <div>
            <Modal id={"ModalResponse"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} onClose={onClose}/>
            <div className={`message-container${owner && " owner"}`}>
                <div className="bubble">
                    <p>{testo}</p>
                </div>
                <div className="message-info">
                    {owner ?
                        <span className="nickname">Tu</span>
                        :
                        <span className="nickname">{nickname}</span>
                    }
                    <span className="date">{data_creazione}</span>
                </div>
            </div>
        </div>
    );
};

export default ForumMessageViewer;