import React, {ChangeEvent, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import {doRequest, handleErrorCheck} from "../utils";

interface Props {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
}

const TextAlphaNumeric: React.FC<Props> = ({ value, onChange, label, placeholder = 'Enter your email', obbligatorio = false, errore = "", check = true }) => {
    const defaultErrore = "Il campo deve essere alfanumerico"
    const [err, setErrore] = useState("");

    const checkErrori = async () => {
        await handleErrorCheck("AlphaNumeric", value, setErrore, err, errore, defaultErrore);
    }

    return (
        <div style={{marginBottom: '16px'}}>
            <label htmlFor="Email" style={{display: 'block', marginBottom: '8px', fontWeight: 'bold'}}>
                {label}{obbligatorio ? " (*)" : ""}
            </label>
            <input
                type="text"
                id="textbox"
                value={value}
                onChange={onChange}
                onBlur={check ? checkErrori : undefined}
                placeholder={placeholder}
                style={{
                    padding: '8px',
                    fontSize: '16px',
                    width: '100%',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                }}
            />
            <ErrorLabel label={err} />
        </div>
    );
};

export default TextAlphaNumeric;