import React, {useEffect, useRef, useState} from "react";
import "../styles/Card.css";
import L from "leaflet";
import TextLetters from "./TextLetters";
import {useStateModal, useStateText} from "../utils";
import "../styles/Location.css";
import Modal from "./Modal";
import Label from "./Label";

interface ComponentProps {
    id: string;
    Loc?: object;
    setLocationObject?: (s : any) => void;
    edit?: boolean
}

const Location: React.FC<ComponentProps> = ({id,edit = false, Loc, setLocationObject = (s : object) => {}}) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const mapInstanceRef = useRef<L.Map | null>(null);
    const markerRef = useRef<L.Marker | null>(null);

    const [Nazione, setNazione, setNazioneDirect] = useStateText();
    const [Comune, setComune, setComuneDirect] = useStateText();
    const [ViaCivico, setViaCivico, setCivicoDirect] = useStateText();
    const [CodicePostale, setCodicePostale, setCodicePostaleDirect] = useStateText();

    const [error, setError] = useState<string>('');

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onModalClose, setOnModalClose] = useStateModal()

    const insertExistingElements = () => {
        if(Loc !== undefined && Loc !== null){
            if("nazione" in Loc && Loc.nazione != null)
                setNazioneDirect(String(Loc.nazione));
            if("comune" in Loc && Loc.comune != null)
                setComuneDirect(String(Loc.comune));
            if("via_civico" in Loc && Loc.via_civico != null)
                setCivicoDirect(String(Loc.via_civico));
            if("codice_postale" in Loc && Loc.codice_postale != null)
                setCodicePostaleDirect(String(Loc.codice_postale));
        }
    }

    useEffect(() => {
        insertExistingElements();

        if (!mapRef.current) return;

        const map = L.map(mapRef.current);
        mapInstanceRef.current = map;

        const marker = L.marker([41.9028, 12.4964]).addTo(map);
        markerRef.current = marker;

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 25,
            attribution: "© OpenStreetMap",
        }).addTo(map);

        if(Loc !== undefined && Loc !== null) {
            if (Object.keys(Loc).length > 0) {
                handleSearch();
            }
        }else{
            console.log("Luogo non ancora ricevuto dal componente padre");
        }

        return () => {
            map.remove();
        };
    }, [Loc]);

    const handleSearch = () => {
        let query = `${Nazione} ${Comune} ${CodicePostale} ${ViaCivico}`
        if(query.trim() != "") {
            setError("");
            console.log(query);
            fetch(`https://nominatim.openstreetmap.org/search?format=json&addressdetails=&q=${encodeURIComponent(query)}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.length > 0) {
                        const place = data[0];
                        const lat = parseFloat(place.lat);
                        const lon = parseFloat(place.lon);

                        if (mapInstanceRef.current && markerRef.current) {
                            mapInstanceRef.current.setView([lat, lon], 15);
                            markerRef.current.setLatLng([lat, lon]);
                        }
                    } else {
                        //setTitoloModale("Errore ricerca luogo")
                        //setCorpoModale("Luogo non individuato");
                        //setMostra(true);
                        setError("Luogo non individuato");
                    }
                })
                .catch((error) => {
                    console.error("Errore ricerca:", error);
                });
        }
    };

    const onBlur = () => {
        setLocationObject({nazione : Nazione, comune : Comune, codice_postale : CodicePostale, via_civico : ViaCivico});
    }

    return (
        <div className={"row"}>
            <Modal id={"ModalResponse1"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} setMostra={setMostra} />

            <div className={"col-lg-4"} >
                {edit ?
                    <div>
                        <TextLetters id={`${id}_nazione`} onBlur={onBlur} value={Nazione} onChange={setNazione} label={"Nazione"} check={false} submitWhenEnter={false}/>
                        <TextLetters id={`${id}_citta`} onBlur={onBlur} value={Comune} onChange={setComune} label={"Città"} check={false} submitWhenEnter={false}/>
                        <TextLetters id={`${id}_via_civico`} onBlur={onBlur} value={ViaCivico} onChange={setViaCivico} label={"Via e civico (specificare via, strada ecc.)"} check={false} submitWhenEnter={false}/>
                        <TextLetters id={`${id}_codice_postale`} onBlur={onBlur} value={CodicePostale} onChange={setCodicePostale} label={"Codice postale"} check={false} submitWhenEnter={false}/>
                        <a id={`${id}_button_location`} className={"btn btn-secondary"} onClick={(e) => {onBlur(); handleSearch();}}>Cerca luogo</a>
                    </div>
                    :
                    <div className={"fs-5"}>
                        {Nazione != "" && <Label label={"Nazione: "} />}{Nazione}
                        <br/>
                        {Comune != "" && <Label label={"Città / Comune: "} />}{Comune}
                        <br/>
                        {ViaCivico != "" && <Label label={"Via e civico: "} /> }{ViaCivico}
                        <br/>
                        {CodicePostale != "" && <Label label={"Codice postale: "} /> }{CodicePostale}
                    </div>
                }
            </div>
            <div className={"col-lg-8"} key={Object.keys(Loc ? Loc : {}).length}>
                <div id="map" className={"map position-relative"} ref={mapRef} style={{height: "500px", width: "100%"}}>
                    {(error && error != "") ? <div id="map-overlay" className={"not-found"}>{error}</div> : <div />}
                </div>
            </div>
        </div>
    );
};

export default Location;
