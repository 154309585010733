import React, {useEffect} from 'react';
import {doRequest, refresh_page, useStateEmail, useStateModal, useStateSelect, useStateText} from "../utils";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import "../styles/Jumbotron.css"
import SubmitButton from "../generic_components/SubmitButton";
import "../styles/GenericLink.css"
import Modal from "../generic_components/Modal";

interface pageProps {
    title : string
}

const ResetPassword: React.FC<pageProps> = ({title}) => {
    useEffect(() => {
        document.title = title
    }, []);

    const [email,handleEmailChange ] = useStateEmail();

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setOnClose] = useStateModal();

    const handleSubmit = async () => {
        let response = await doRequest("/NotLoggedUser/AskResetPassword", {email}, false);
        if(response !== undefined){
            if("RISULTATO" in response) {
                setCorpoModale("Reset password richiesto, verifica la tua email");
                setTitoloModale("OPERAZIONE EFFETTUATA");
                //const to_reset = () => {window.location.href = "/DoResetPassword/" + response.RISULTATO};
                setOnClose(() => refresh_page);
                setTimeout(()=>{
                    setMostra(true);
                }, 300)
            }
            else{
                setCorpoModale("Reset password fallito");
                setTitoloModale("OPERAZIONE FALLITA");
                setOnClose(() => {});
                setTimeout(()=>{
                    setMostra(true);
                }, 300)
            }
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <Modal id={"ModalResponse_ResetPassword"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} setMostra={setMostra} onClose={onClose} />
            <div className="container-fluid jumbotron-login jumbotron col-lg-8 border" style={{borderStyle : "solid", borderRadius: "30px"}}>
                <div className="row container-fluid py-4">
                    <div className="col-lg-12">
                        <h1 className="text-center">
                            RESET PASSWORD
                        </h1>
                        <br/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={"col-lg-8"} />
                    <div className="col-lg-8 py-4">
                        <div className="col-lg-12">
                            <form role="form">
                                <div className="form-group">
                                    <div className={"row"}>
                                        <Email value={email} onChange={handleEmailChange} label="E-mail" placeholder="tuamail@dominio.com" check={false}/>
                                    </div>
                                    <div className={"row"}>
                                        <SubmitButton label={"RESET"} onSubmit={handleSubmit}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;