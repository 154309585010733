import React, {useEffect, useState} from 'react';
import {
    doRequest,
    ThemeOptions, to_home,
    useStateEmail, useStateFile, useStateModal,
    useStateNumber, useStateNumberDefault,
    useStateSelect,
    useStateText,
    useStateTextArea
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import TextLetters from "../generic_components/TextLetters";
import TextArea from "../generic_components/TextArea";
import Select from "../generic_components/Select";
import {wait} from "@testing-library/user-event/dist/utils";
import NumberGradientSelector from "../generic_components/NumberGradientSelector";
import ButtonIcon from "../generic_components/ButtonIcon";
import SubmitButton from "../generic_components/SubmitButton";
import Modal from "../generic_components/Modal";
import FileUpload from "../generic_components/FileUpload";

interface pageProps {
    title : string
}

const CreateItinerary: React.FC<pageProps> = ({title}) => {
    const [ItineraryTitle, setItineraryTitle] = useStateText();
    const [ItineraryDescription, setItineraryDescription] = useStateTextArea();
    const [Tema, setTema] = useStateSelect();
    const [Difficulty, setDifficulty] = useStateNumberDefault(1);
    const [CoverImage, setCoverImage] = useStateFile();

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onModalClose, setOnModalClose] = useStateModal()

    useEffect(() => {
        document.title = title
    }, []);


    const handleSubmit = async () => {
        setMostra(false);
        setOnModalClose(() => {});
        let response = await doRequest("/Itinerary/CreateItinerary", {ItineraryTitle, ItineraryDescription, Tema, Difficulty, CoverImage}, true);
        console.log(response)
        if(response !== undefined && response !== null){
            if("RISULTATO" in response){
                let esito = response.RISULTATO;
                setCorpoModale("Itinerario inserito correttamente");
                setTitoloModale("OPERAZIONE EFFETTUATA");
                const to_edit = () => {window.location.href = ("/EditStops/" + response.RISULTATO)}
                setOnModalClose(() => to_edit);
                setTimeout(() => {
                    setMostra(true);
                },300)
            }
            if("ERRORE" in response){
                let esito = response.ERRORE;
                setCorpoModale("Inserimento itinerario fallito");
                setTitoloModale("OPERAZIONE FALLITA");
                setTimeout(() => {
                    setMostra(true);
                },300)
            }
        }else{
            setCorpoModale("Impossibile comunicare col server");
            setTitoloModale("OPERAZIONE FALLITA");
        }

    };

    return (
        <div className="container">
            <Modal id={"ModalResponse"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} onClose={onModalClose} />
            <div className="row container-fluid py-4 jumbotron-login border" style={{borderStyle : "solid", borderRadius: "30px"}}>
                <div className="row container-fluid">
                    <h2 className={"h15 margin-contorno-10"}>
                        Informazioni di base
                    </h2>
                    <form role="form">
                        <div className="form-group">
                            <div className="row container-fluid">
                                <div className="col-lg-4">
                                    <TextLetters value={ItineraryTitle} onChange={setItineraryTitle}
                                                 label={"Titolo itinerario"} obbligatorio={true}/>
                                </div>
                                <div className="col-lg-4">
                                    <Select href={"/GetConstants/ItineraryThemeOptions"} id={"tema_itin"}
                                            obbligatorio={true} onChange={setTema} label={"Tema"}/>
                                </div>
                                <div className={"col-lg-4"}>
                                    <NumberGradientSelector label={"Seleziona la difficoltà (da 1 a 10)"}
                                                        obbligatorio={true}
                                                        length={10} value={Difficulty} setValue={setDifficulty}/>
                                </div>
                            </div>
                            <div className="row container-fluid">
                                <TextArea id={"desc_itin"} value={ItineraryDescription}
                                          onChange={setItineraryDescription}
                                          label={"Descrizione"}/>
                            </div>
                            <div className="row container-fluid justify-content-center align-content-center py-4">
                                <FileUpload id={"img_copertina"} value={CoverImage} onChange={setCoverImage} label={"Copertina"}/>
                            </div>
                            <div className="row container-fluid justify-content-center align-content-center">
                                {/* <ButtonIcon icon={"fa-check"} href={"/EditItinerary"} operazione={"CREA"} size={"5"} /> */}
                                <SubmitButton label={"CONFERMA"} onSubmit={handleSubmit}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateItinerary;