import React, {useEffect, useState} from 'react';
import {
    doRequest, refresh_page,
    ThemeOptions, to_home, usePhoneNumberSelect,
    useStateEmail, useStateFile, useStateModal,
    useStateNumber, useStateNumberDefault,
    useStateSelect,
    useStateText,
    useStateTextArea
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import TextLetters from "../generic_components/TextLetters";
import TextArea from "../generic_components/TextArea";
import Select from "../generic_components/Select";
import {wait} from "@testing-library/user-event/dist/utils";
import NumberGradientSelector from "../generic_components/NumberGradientSelector";
import ButtonIcon from "../generic_components/ButtonIcon";
import SubmitButton from "../generic_components/SubmitButton";
import Modal from "../generic_components/Modal";
import FileUpload from "../generic_components/FileUpload";
import {usePhoneInput} from "react-international-phone";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import TextAlphaNumeric from "../generic_components/TextAlphaNumeric";
import PhoneNumber from "../generic_components/PhoneNumber";
import {useParams} from "react-router-dom";
import Label from "../generic_components/Label";
import List from "../generic_components/List";
import Img from "../generic_components/Img";

interface pageProps {
    title : string
}

const AccountView: React.FC<pageProps> = ({title}) => {
    useEffect(() => {
        document.title = title
    }, []);

    const {id} = useParams();

    const [nickname, setNickname] = useState();
    const [email, setEmail] = useState();
    const [nominativo, setNominativo] = useState();
    const [numero, setNumero] = useState();
    const [badge, setBadge] = useState<boolean>();
    const [badgeItinerari, setBadgeItinerari] = useState<boolean>();

    const [followed, setFollowed] = useState<boolean>(false);

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setOnClose] = useStateModal()

    useEffect(() => {
        const fetchData = async () => {
            const response = await doRequest("/User/GetInfo", {UserId : id }, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    let account_data = response.RISULTATO[0];
                    try{
                        setNickname(account_data.nickname);
                        setEmail(account_data.email);
                        setNominativo(account_data.nominativo);
                        setNumero(account_data.numero_di_telefono);
                        setBadge(account_data.badge);
                        setBadgeItinerari(account_data.badge_itinerari);
                    }catch (e){
                        console.log(e);
                    }
                }
            }else{
                console.error("Errore richiesta");
            }

            const response_followed = await doRequest("/User/GetIfFollow", {UserId : id }, true);
            if (response_followed && "RISULTATO" in response_followed) {
                try{
                    setFollowed(response_followed.RISULTATO);
                }catch (e){}
            }
        };

        fetchData();
    }, []);

    const followOrUnfollow = async (s : string) => {
        let response = await doRequest(`/User/Follow/${s}`, {UserId : id}, true);
        if(response !== undefined && response !== null){
            if("RISULTATO" in response){;
                setCorpoModale("Gestione follow eseguita.");
                setTitoloModale("OPERAZIONE EFFETTUATA");
                setOnClose(() => refresh_page);
                setTimeout(() => {
                    setMostra(true);
                },300)
                setMostra(true);
            }
            if("ERRORE" in response){
                setCorpoModale("Gestione follow non eseguita");
                setTitoloModale("OPERAZIONE FALLITA");
                setMostra(true);
            }
        }else{
            setCorpoModale("Impossibile comunicare col server");
            setTitoloModale("OPERAZIONE FALLITA");
            setMostra(true);
        }
    }

    return (
        <div className="row container-fluid d-flex py-4 justify-content-center">
            <Modal id={"ModalResponse"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} setMostra={setMostra} onClose={onClose}/>
            <div className={"row"}>
                <h1 className="text-center">
                    VISUALIZZA INFORMAZIONI ACCOUNT
                </h1>
            </div>
            <div className="row container-fluid jumbotron-signup jumbotron col-lg-8 border py-4"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <div className="row justify-content-center">
                    <div className={"col-lg-8"}/>
                    <div className="row col-lg-11">
                        <div className="col-lg-8">
                            <div className="form-group">
                                <div className="row">
                                    <div className={"col-lg-6 container-fluid"}>
                                        <Label label={"Email:"}/>
                                        <h5>{email}</h5>
                                    </div>
                                    <div className={"col-lg-6 container-fluid"}>
                                        <Label label={"Nickname:"}/>
                                        <h5>{nickname}</h5>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-lg-6 container-fluid"}>
                                        <Label label={"Nominativo:"}/>
                                        <h5>{nominativo}</h5>
                                    </div>
                                    <div className={"col-lg-6 container-fluid"}>
                                        <Label label={"Numero di telefono:"}/>
                                        <h5>{numero}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex align-items-center col-lg-4"}>
                            { followed
                            ?
                                <ButtonIcon icon_stack={"fa-slash"} icon={"fa-user-plus"} size={"2"} operazione={"Unfollow"} onClick={() => {followOrUnfollow("false")}}/>
                            :
                                <ButtonIcon icon={"fa-user-plus"} size={"2"} operazione={"Follow"} onClick={() => {followOrUnfollow("true")}}/>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className={"row container-fluid py-3"}></div>
            <div className={"row container-fluid jumbotron-login col-lg-8 border py-4"}
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <div className="row py-1">
                    <div className={"d-flex justify-content-between"}>
                        <a className="" data-bs-toggle="collapse" href="#itineraries" role="button">
                            <h4>Itinerari con valutazione più alta</h4>
                        </a>
                        <a className="" data-bs-toggle="collapse" href="#badges" role="button">
                            <h4>Badge ottenute</h4>
                        </a>
                    </div>
                </div>
                <div id={"itineraries"} className="row collapse ">
                    <List id={`itinerary_list`}
                          isCarousel={true}
                          CurrentPage={1}
                          PerPageNumber={5}
                          endpoint={"/Itinerary/GetItinerary"}
                          editable={false}
                          filters={{UserId : id}}
                    />
                </div>
                <div key={"" + badge + badgeItinerari} id={"badges"} className="row collapse ">
                    {(badge || badgeItinerari)
                        ?
                        <div className={"row container-fluid"}>
                            {badge &&
                            <div className={"d-flex justify-content-center col-lg-6"}>
                                <Img id={"badge_img"} img_path={"/badge_follower.png"} value_override={"200px"} param={"width"}/>
                            </div>
                            }
                            {badgeItinerari &&
                            <div className={"d-flex justify-content-center col-lg-6"}>
                                <Img id={"badge_itinerari_img"} img_path={"/badge_itinerari.png"} value_override={"200px"} param={"width"}/>
                            </div>
                            }
                        </div>
                        :
                        <h5>Nessuna badge disponibile</h5>
                    }
                </div>
            </div>
        </div>
    );
};

export default AccountView;