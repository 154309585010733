import React, {useEffect, useState} from 'react';
import TextLetters from "./TextLetters";
import {
    doRequest,
    refresh_page,
    removeColorsFromHTML,
    useStateModal,
    useStateNumberDefault,
    useStateText,
    useStateTextArea
} from "../utils";
import TextArea from "./TextArea";
import Label from "./Label";
import SubmitButton from "./SubmitButton";
import NumberGradientSelector from "./NumberGradientSelector";
import Modal from "./Modal";

interface ComponentProps {
    itinerary_id : number;
    Feedback : any;
}

const Feedback: React.FC<ComponentProps> = ({ itinerary_id, Feedback }) => {

    useEffect(() => {

    }, [itinerary_id]);

    return (
        <div className={"form-group"}>
            <div className={"row container-fluid"}>
                <h3>{Feedback.titolo}</h3>
            </div>
            <div className={"row container-fluid"}>
                <div className={"col-lg-8"}>
                    <div dangerouslySetInnerHTML={{__html: removeColorsFromHTML(Feedback.descrizione)}}/>
                </div>
            </div>
            <hr/>
            <div className={"row container-fluid"}>
                <NumberGradientSelector type={"stars"} editable={false} label={""} length={5} value={Feedback.punteggio} setValue={() => {}} obbligatorio={false} />
            </div>
            <br/>
        </div>
    );
};

export default Feedback;