import React, {ChangeEvent, useState} from 'react';
import {PhoneInput} from "react-international-phone";
import "../styles/PhoneInput.css"
import ErrorLabel from "./ErrorLabel";
import {handleErrorCheck} from "../utils";


interface PhoneNumberProps {
    id: string;
    value: string;
    onChange: (phone: any) => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ id, value, onChange, label, placeholder = '...', obbligatorio = false, errore = "", check = true}) => {
    const defaultErrore = "Il numero telefonico non è valido"
    const [err, setErrore] = useState("");

    const checkErrori = async () => {
        await handleErrorCheck("PhoneNumber", value, setErrore, err, errore, defaultErrore);
    }

    return (
        <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
                {label}{obbligatorio ? " (*)" : ""}
            </label>
            <PhoneInput className={"phone-input"} defaultCountry="it" value={value} onChange={onChange} onBlur={check ? checkErrori : undefined}/>
            <ErrorLabel label={err} />
        </div>
    );
};

export default PhoneNumber;