import React, {ChangeEvent, useEffect, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import {doRequest, handleErrorCheck, useStateText} from "../utils";
import Label from "./Label";
import Textbox from "./Textbox";
import TextNumber from "./TextNumber";
import TextTime from "./TextTime";

interface Props {
    id? : string;
    value: string;
    onChange: (s : string) => void;
    onBlur?: () => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
    submitWhenEnter?: boolean;
    icon?: string;
    icon_size?: string;
    icon_onclick?: () => void;
    type?: string;
}

const TextMinMax: React.FC<Props> = ({ id = "textbox",icon,type = "number", icon_size, icon_onclick, submitWhenEnter = true, onChange, onBlur = () => {}, label, placeholder = '', obbligatorio = false, errore = "", check = true }) => {
    const [min, setMin] = useStateText();
    const [max, setMax] = useStateText();

    const [minTime, setMinTime] = useState<number>();
    const [maxTime, setMaxTime] = useState<number>();

    useEffect(() => {
        if(!submitWhenEnter){
            $(document).on("keydown", `#${id}`, function(event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                }
            });
        }
    }, []);

    useEffect(() => {
        if(type == "number")
            onChange(min + '|' + max);
        if(type == "time")
            onChange((minTime != undefined ? minTime : 0) + '|' + (maxTime != undefined ? maxTime : 100000000));
    }, [min, max, minTime, maxTime]);

    return (
        <div className={"row"} style={{marginBottom: '16px'}}>
            <div className={"col-12"}>
                <Label label={label} />
            </div>
            {type == "number" &&
                <div className={"col-10"}>
                    <TextNumber value={min} placeholder={"Min"} label={""} onChange={setMin} />
                    <TextNumber value={max} placeholder={"Max"} label={""} onChange={setMax} />
                </div>
            }
            {type == "time" &&
                <div className={"col-10"}>
                    <TextTime value={min} label={""} placeholder={"Min"} setTotalSeconds={setMinTime} />
                    <TextTime value={max} label={""} placeholder={"Max"} setTotalSeconds={setMaxTime} />
                </div>
            }
            <div className={"col-2 d-flex justify-content-center py-0"}>
                {icon && <span className={"input-group-text cliccabile"} onClick={icon_onclick}><center><i className={"fa-solid " + icon + " fa-" + icon_size + "x"}/></center></span>}
            </div>
        </div>
    );
};

export default TextMinMax;