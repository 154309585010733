import React, {useEffect, useState} from 'react';
import {
    doRequest,
    setFormError, to_home,
    usePhoneNumberSelect,
    useStateEmail, useStateModal,
    useStateSelect,
    useStateText
} from "../utils";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import "../styles/Jumbotron.css"
import SubmitButton from "../generic_components/SubmitButton";
import "../styles/GenericLink.css"
import 'react-international-phone/style.css';
import PhoneNumber from "../generic_components/PhoneNumber";
import TextAlphaNumeric from "../generic_components/TextAlphaNumeric";
import TextLetters from "../generic_components/TextLetters";
import Modal from "../generic_components/Modal";
import {wait} from "@testing-library/user-event/dist/utils";

interface pageProps {
    title : string
}

const SignUp: React.FC<pageProps> = ({title}) => {
    useEffect(() => {
        document.title = title
    }, []);

    const [nickname, handleNickChange] = useStateText();
    const [email, handleEmailChange] = useStateEmail();
    const [password, handleTextChange] = useStateText();
    const [nome, handleNomeChange] = useStateText();
    const [cognome, handleCognomeChange] = useStateText();
    const [numero, handleNumeroChange] = usePhoneNumberSelect();

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setOnClose] = useStateModal()


    const handleSubmit = async () => {
        setMostra(false);
        let response = await doRequest("/NotLoggedUser/SignUp", {nickname, email, password, nome, cognome, numero}, false);
        //console.log(response);
        if(response !== undefined && response !== null){
            if("RISULTATO" in response){
                let esito = response.RISULTATO;
                setCorpoModale(esito);
                setTitoloModale("OPERAZIONE EFFETTUATA");
                setOnClose(() => to_home);
                setMostra(true);
            }
            if("ERRORE" in response){
                let esito = response.ERRORE;
                setCorpoModale(esito);
                setTitoloModale("OPERAZIONE FALLITA");
                setOnClose(() => to_home);
                setMostra(true);
            }
        }else{
            setCorpoModale("Impossibile comunicare col server");
            setTitoloModale("OPERAZIONE FALLITA");
        }

    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <Modal id={"ModalResponse"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} setMostra={setMostra} onClose={onClose} />
            <div className="container-fluid jumbotron-signup jumbotron col-lg-8 border" style={{borderStyle : "solid", borderRadius: "30px"}}>
                <div className="row container-fluid py-4">
                    <div className="col-lg-12">
                        <h1 className="text-center">
                            REGISTRAZIONE
                        </h1>
                        <h3 className="text-center">
                            In questa pagina puoi effettuare la registrazione
                            <h5>I campi contrassegnati da un asterisco (*) sono obbligatori</h5>
                        </h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                <div className={"col-lg-8"} />
                    <div className="col-lg-8">
                        <div className="col-lg-12">
                            <form role="form">
                                <div className="form-group">
                                    {/* <Select id={"select"} label={"Seleziona"} options={options} onChange={handleSelectionChange} /> */}
                                    <div className={"row container-fluid"}>
                                        <Email value={email} onChange={handleEmailChange} label="E-mail" placeholder="tuamail@dominio.com" obbligatorio={true}/>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <Password id={"password"} value={password} onChange={handleTextChange} label="Password" placeholder="***" obbligatorio={true}/>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <TextAlphaNumeric value={nickname} onChange={handleNickChange} label="Nickname" placeholder="" obbligatorio={true}/>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <div className={"col-lg-4"}>
                                            <TextLetters value={nome} onChange={handleNomeChange} label="Nome" placeholder="" obbligatorio={true}/>
                                        </div>
                                        <div className={"col-lg-4"}>
                                            <TextLetters value={cognome} onChange={handleCognomeChange} label="Cognome" placeholder="" obbligatorio={true}/>
                                        </div>
                                        <div className={"col-lg-4"}>
                                            <PhoneNumber id={"phonenumber"} value={numero} onChange={handleNumeroChange} label={"Numero di telefono"} obbligatorio={true}/>
                                        </div>
                                    </div>
                                    <div className={"row container-fluid"}>
                                        <div className={"col-lg-2"} />
                                        <div className={"col-lg-8"}>
                                            <SubmitButton color={"azzurro"} label={"REGISTRATI"} onSubmit={handleSubmit}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={"col-lg-6"}>
                            <a href={"/Login"} className={"generic-link"}> Già registrato? Effettua il login</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;