import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from "./generic_pages/Login";
import Navbar from "./generic_components/Navbar";
import SignUp from "./generic_pages/SignUp";
import Home from "./generic_pages/Home";
import Logout from "./generic_pages/Logout";
import {checkToken} from "./utils";
import CreateItinerary from "./generic_pages/CreateItinerary";
import EditStops from "./generic_pages/EditStops";
import SearchItinerary from "./generic_pages/SearchItinerary";
import YourItineraries from "./generic_pages/YourItineraries";
import EditItinerary from "./generic_pages/EditItinerary";
import ViewStops from "./generic_pages/ViewStops";
import ViewFeedback from "./generic_pages/ViewFeedback";
import ResetPassword from "./generic_pages/ResetPassword";
import DoResetPassword from "./generic_pages/DoResetPassword";
import AccountEdit from "./generic_pages/AccountEdit";
import AccountView from "./generic_pages/AccountView";
import Forum from "./generic_pages/Forum";
import ForumMessages from "./generic_pages/ForumMessages";
import HomeNotLogged from "./generic_pages/HomeNotLogged";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const SiteName = "ITINERARI - "

const logged = await checkToken();
const LoginPage = <Login title={SiteName + "Login"} />

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Navbar />
          <Routes>
              <Route path="/Login" element={LoginPage} />
              <Route path="/SignUp" element={<SignUp title={SiteName + "Registrazione"} />} />
              <Route path="/ResetPassword" element={<ResetPassword title={SiteName + "Reset password"} />} />
              <Route path="/DoResetPassword/:reset_token" element={<DoResetPassword title={SiteName + "Reset password"} />} />
              <Route path="/Logout" element={<Logout title={SiteName + "Logout"} />} />

              <Route path="/" element={logged ?  <Home title={SiteName + "Home"}/> : <HomeNotLogged title={"Home"} />} />
              <Route path="/Home" element={logged ?  <Home title={SiteName + "Home"}/> : <HomeNotLogged title={"Home"} />} />

              <Route path="/CreateItinerary" element={logged ?  <CreateItinerary title={SiteName + "Crea Itinerario"}/> : LoginPage } />
              <Route path="/EditItinerary/:id" element={logged ?  <EditItinerary title={SiteName + "Modifica Itinerario"}/> : LoginPage } />
              <Route path="/SearchItinerary" element={logged ?  <SearchItinerary title={SiteName + "Ricerca Itinerario"}/> : LoginPage } />
              <Route path="/YourItineraries" element={logged ?  <YourItineraries title={SiteName + "Gestisci itinerari"}/> : LoginPage } />

              <Route path="/EditStops/:id" element={logged ?  <EditStops title={SiteName + "Gestisci Tappe"}/> : LoginPage } />
              <Route path="/ViewStops/:id" element={logged ?  <ViewStops title={SiteName + "Elenco Tappe"}/> : LoginPage } />

              <Route path="/ViewFeedback/:id" element={logged ?  <ViewFeedback title={SiteName + "Visualizza feedback"}/> : LoginPage } />

              <Route path="/MyAccount" element={logged ?  <AccountEdit title={SiteName + "Modifica info account"}/> : LoginPage } />
              <Route path="/AccountView/:id" element={logged ?  <AccountView title={SiteName + "Visualizza account"}/> : LoginPage } />

              <Route path="/Forum/:itinerary_id" element={logged ?  <Forum title={SiteName + "Forum"}/> : LoginPage } />
              <Route path="/ForumMessages/:itinerary_id/:forum_id" element={logged ?  <ForumMessages title={SiteName + "Forum"}/> : LoginPage } />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
