import React, {useEffect, useState} from 'react';
import TextLetters from "./TextLetters";
import {doRequest, refresh_page, useStateModal, useStateNumberDefault, useStateText, useStateTextArea} from "../utils";
import TextArea from "./TextArea";
import Label from "./Label";
import SubmitButton from "./SubmitButton";
import NumberGradientSelector from "./NumberGradientSelector";
import Modal from "./Modal";

interface ComponentProps {
    itinerary_id : number;
}

const Feedback: React.FC<ComponentProps> = ({ itinerary_id }) => {
    const [FeedbackTitle, setFeedbackTitle, setFeedbackTitleDirect] = useStateText();
    const [FeedbackDescription, setFeedbackDescription, setFeedbackDescriptionDirect] = useStateTextArea();
    const [FeedbackStars, setFeedbackStars, setFeedbackStarsDirect] = useStateNumberDefault(1);

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onModalClose, setModalOnClose] = useStateModal();

    useEffect(() => {
        const fetchData = async () => {
            const response = await doRequest("/Itinerary/GetFeedback/Owner", {ItineraryId : itinerary_id}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    let feedback_data = response.RISULTATO[0];
                    try{
                        setFeedbackTitleDirect(feedback_data.titolo);
                        setFeedbackDescriptionDirect(feedback_data.descrizione);
                        setFeedbackStarsDirect(feedback_data.punteggio);
                    }catch (e){
                        console.log(e);
                    }
                }
            }else{
                console.error("Errore richiesta");
            }
        };

        fetchData();
    }, [itinerary_id]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        let response = await doRequest("/Itinerary/UpsertFeedback", {ItineraryId : itinerary_id, FeedbackTitle, FeedbackDescription, FeedbackStars}, true);
        if(response !== undefined && response !== null){
            if("RISULTATO" in response){;
                setCorpoModale("Il feedback è stato inserito");
                setTitoloModale("OPERAZIONE EFFETTUATA");
                setModalOnClose(() => refresh_page);
                setTimeout(() => {
                    setMostra(true);
                },300)
                setMostra(true);
            }
            if("ERRORE" in response){
                setCorpoModale("Il feedback non è stato inserito");
                setTitoloModale("OPERAZIONE FALLITA");
                setMostra(true);
            }
        }else{
            setCorpoModale("Impossibile comunicare col server");
            setTitoloModale("OPERAZIONE FALLITA");
            setMostra(true);
        }
    };

    return (
        <form role={"form"} key={itinerary_id}>
            <Modal id={"ModalResponse_Feedback"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} onClose={onModalClose} />
            <div className={"form-group"}>
                <div className={"row container-fluid"}>
                    <TextLetters value={FeedbackTitle} onChange={setFeedbackTitle} label={"Titolo"} />
                </div>
                <div className={"row container-fluid"}>
                    <div className={"col-lg-8"}>
                        <TextArea label={"Valutazione descrittiva:"} id={"feedback_description"} value={FeedbackDescription} onChange={setFeedbackDescription} />
                    </div>
                </div>
                <hr/>
                <div className={"row container-fluid"}>
                    <NumberGradientSelector type={"stars"} label={"Valutazione tramite stelle:"} length={5} value={FeedbackStars} setValue={setFeedbackStars} obbligatorio={false} />
                </div>
                <br/>
                <div className={"row container-fluid"}>
                    <SubmitButton label={"PUBBLICA FEEDBACK"} onSubmit={handleSubmit}/>
                </div>
            </div>
        </form>
    );
};

export default Feedback;