import React, {useEffect, useState} from 'react';
import {
    addOrRemoveElementToCarousel,
    backend_path, backend_path_slash,
    doRequest, ItineraryFilterOptions, Option, refresh_page,
    ThemeOptions,
    useStateEmail, useStateFile, useStateModal, useStateMultiple, useStateSelect, useStateText, useStateTextArea,
} from "../utils";

import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import {useParams} from "react-router-dom";
import CarouselHead from "../generic_components/CarouselHead";
import "../styles/Carousel.css"
import List from "../generic_components/List";
import Filters from "../generic_components/Filters";
import filters from "../generic_components/Filters";
import ItineraryDetailsViewer from "../specific_components/ItineraryDetailsViewer";
import TextArea from "../generic_components/TextArea";
import SubmitButton from "../generic_components/SubmitButton";
import Label from "../generic_components/Label";
import Modal from "../generic_components/Modal";
import ForumMessageViewer from "../specific_components/ForumMessageViewer";
import ButtonIcon from "../generic_components/ButtonIcon";

interface pageProps {
    title : string
}

const Forum: React.FC<pageProps> = ({title}) => {
    const { itinerary_id, forum_id } = useParams();
    const [ForumData, setForumdata] = useState([]);

    const [NewMessage, setNewMessage] = useStateTextArea();

    useEffect(() => {
        document.title = title

        const fetchData = async () => {
            const response = await doRequest("/Forum/GetForum", {ForumId : forum_id, ItineraryId : itinerary_id}, true);
            console.log("response");
            console.log(response);

            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                if(response.RISULTATO.length > 0) {
                    console.log(response.RISULTATO);
                    setForumdata(response.RISULTATO);
                }
            }else{
                console.error("Errore richiesta");
            }
        };

        fetchData().then(() => {
            const div_messaggi = document.getElementById('div_messaggi');
            if(div_messaggi)
                div_messaggi.scrollTop = div_messaggi.scrollHeight;
        });

    }, []);

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setOnClose] = useStateModal();

    const sendMessage = async () => {
        const response = await doRequest("/Forum/SendMessage", {ForumId : forum_id, Testo : NewMessage}, true);
        if (response && "RISULTATO" in response) {
            setCorpoModale("Messaggio inviato");
            setTitoloModale("OPERAZIONE ESEGUITA");
            setOnClose(() => refresh_page);
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }else{
            setCorpoModale("Invio messaggio non eseguito");
            setTitoloModale("OPERAZIONE FALLITA");
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }
    }

    const closeForum = async () => {
        const response = await doRequest("/Forum/Close", {ForumId : forum_id}, true);
        if (response && "RISULTATO" in response) {
            setCorpoModale("Forum chiuso");
            setTitoloModale("OPERAZIONE ESEGUITA");
            setOnClose(() => refresh_page);
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }else{
            setCorpoModale("Chiusura forum non riuscita");
            setTitoloModale("OPERAZIONE FALLITA");
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }
    }

    return (
        <div className={"container"}>
            <Modal id={"login_modale"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} setMostra={setMostra} onClose={onClose} />
            <div className="row min-vh-10">
                <div className="row">
                    <div className="col-lg-2 d-flex align-items-center">
                        <ButtonIcon icon={"fa-arrow-left"} size={"2"} font_size={"12px"} operazione={"FORUM"} onClick={() => {window.location.href = `/Forum/${itinerary_id}`}}/>
                    </div>
                    <div className="col-auto">
                        <h1 className="text-center margin-contorno-25">
                            FORUM - {ForumData && ForumData.length > 0 && (ForumData[0] as any).titolo}
                        </h1>
                    </div>
                </div>
            </div>
            <ItineraryDetailsViewer actions={false} editable={false} feedback_available={false}
                                    itinerary_id={itinerary_id ? parseInt(itinerary_id) : -1}/>
            <br/>
            {(ForumData && ForumData.length > 0 && Array.isArray((ForumData[0] as any).messaggi) && (ForumData[0] as any).messaggi[0].testo != null) &&
                <div key={ForumData.length} id={"div_messaggi"}
                     className="row container-fluid py-4 jumbotron-login overflow-auto border max-vh-60"
                     style={{borderStyle: "solid", borderRadius: "30px"}}>
                    {((ForumData && ForumData.length > 0) && Array.isArray((ForumData[0] as any).messaggi)) &&
                        [...(ForumData[0] as any).messaggi].map((element: any, index) => (
                            <ForumMessageViewer owner={element.owner} testo={element.testo}
                                                nickname={element.nickname_mittente}
                                                data_creazione={element.data_creazione}/>
                        ))
                    }
                </div>
            }
            <br/>
            <div className="row container-fluid py-4 jumbotron-login border"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <div className={"row"}>
                    {(ForumData && ForumData.length > 0 && (ForumData[0] as any).chiuso) == false ?
                        <div>
                            <div className="col-lg-10 col-md-10">
                                <TextArea label={"Nuovo messaggio:"} id={"messaggio"} value={NewMessage}
                                          onChange={setNewMessage}/>
                            </div>
                                <div className="col-lg-2 col-md-2">
                                    <Label label={" "}/>
                                    <SubmitButton onSubmit={sendMessage} label={"INVIA"}/>
                                </div>
                                <div className={"col-lg-12"}>
                                    <br/>
                                    <br/>
                                    {(ForumData && ForumData.length > 0 && (ForumData[0] as any).owner_forum) &&
                                        <div>
                                            <Label label={"Sei il creatore della discussione, quindi puoi procedere alla sua chiusura in qualsiasi momento"}/>
                                            <SubmitButton onSubmit={closeForum} color={"rosso"}
                                                          label={"CHIUDI DISCUSSIONE"}/>
                                        </div>

                                    }
                                </div>
                            </div>
                            :
                            <Label label={"FORUM CHIUSO: non è possibile aggiungere messaggi"} />
                        }

                    </div>
            </div>
        </div>
    );
};

export default Forum;