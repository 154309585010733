import React, {ChangeEvent, useEffect, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import {doRequest, handleErrorCheck, useStateNumber, useStateNumberDefault, useStateText} from "../utils";
import TextNumber from "./TextNumber";
import Label from "./Label";

interface Props {
    id? : string;
    value: string;
    onChange?: (s :string) => void;
    onBlur?: () => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
    submitWhenEnter?: boolean;
    setTotalSeconds?: (n : number) => void;
}

const TextTime: React.FC<Props> = ({ id = "textbox",submitWhenEnter = true,setTotalSeconds, value, onChange = () => {}, onBlur = () => {}, label, placeholder = '', obbligatorio = false, errore = "", check = true }) => {
    const defaultErrore = "Il campo deve avere max 23 ore e max 59 minuti"
    const [err, setErrore] = useState("");

    const [Hours, setHours, setHoursDirect] = useStateText();
    const [Minutes, setMinutes, setMinutesDirect] = useStateText();
    const [Seconds, setSeconds, setSecondsDirect] = useStateText();

    const checkErrori = async () => {
        await handleErrorCheck("Time", value, setErrore, err, errore, defaultErrore);
    }

    useEffect(() => {
        if(value !== null) {
            let time_parts = value.split(":");
            if (time_parts.length === 3) {
                // HH:MM:SS
                setHoursDirect(time_parts[0]);
                setMinutesDirect(time_parts[1]);
                setSecondsDirect(time_parts[2]);
            } else if (time_parts.length === 2) {
                //MM:SS
                setHoursDirect("");
                setMinutesDirect(time_parts[0]);
                setSecondsDirect(time_parts[1]);
            }
        }
    }, [value]);

    useEffect(() => {
        onChange(Hours + ":" + Minutes + ":" + Seconds);
    }, [Hours, Minutes, Seconds]);

    const updateParentState = async () => {
        onChange(Hours + ":" + Minutes + ":" + Seconds);
        if(setTotalSeconds){
            setTotalSeconds(
                (Hours != "" ? parseInt(Hours) * 3600 : 0 ) +
                (Minutes != "" ? parseInt(Minutes) * 60 : 0 ) +
                (Seconds != "" ? parseInt(Seconds) * 3600 : 0 )
            );
        }
    }

    return (
        <div className={"row"} style={{}}>
            <Label label={label} />
            <div className={"row container-fluid"}>
                <div className={"col-lg-6 d-flex align-items-center"}>
                    <TextNumber value={Hours} placeholder={"HH " + placeholder} onChange={setHours} onBlur={() => {checkErrori().then(updateParentState);}} check={false} />
                    &nbsp;
                </div>
                <div className={"col-lg-6 d-flex align-items-center"}>
                    <TextNumber value={Minutes} placeholder={"MM " + placeholder} onChange={setMinutes} onBlur={() => {checkErrori().then(updateParentState);}} check={false}/>
                    &nbsp;
                </div>
                <div className={"col-lg-4 d-flex align-items-center d-none"}>
                    <TextNumber value={Seconds} placeholder={"SS " + placeholder} onChange={setSeconds} onBlur={() => {checkErrori().then(updateParentState);}} check={false}/>
                    &nbsp;
                </div>
            </div>
            <ErrorLabel label={err}/>
        </div>
    );
};

export default TextTime;