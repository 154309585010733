import React from 'react';
import "../styles/Carousel.css"

interface ComponentProps {
    id?: string;
    elements: {img : string, title: string, description: string}[];
}

const Carousel: React.FC<ComponentProps> = ({ id = "IdCarosello", elements }) => {
    return (
        elements && elements.length > 0 ?
            <div id={id} className="carousel slide carousel-margin">
                <div className="carousel-indicators">
                    {elements.map((element, index) => (
                        <button
                            type="button"
                            data-bs-target={"#" + id}
                            data-bs-slide-to={index}
                            className={index === 0 ? "active" : ""}
                            aria-current={index === 0 ? "true" : undefined}
                            aria-label={"Slide" + (index + 1)}
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {elements.map((element, index) => (
                        <div className={"carousel-item " + (index === 0 ? "active" : "")}>
                            <img src={element.img} className="d-block w-100" alt={"Slide" + (index + 1)}/>
                            <div className="carousel-caption d-none d-md-block bg-dark">
                                <h5>{element.title}</h5>
                                <p>{element.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target={"#" + id}
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target={"#" + id}
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div> : <h5>Non ci sono elementi disponibili</h5>
    );
};

export default Carousel;