import React, {useEffect, useState} from 'react';
import {
    doRequest,
    ThemeOptions,
    useStateEmail, useStateFile, useStateModal,
    useStateNumber, useStateNumberDefault,
    useStateSelect,
    useStateText,
    useStateTextArea
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import TextLetters from "../generic_components/TextLetters";
import TextArea from "../generic_components/TextArea";
import Select from "../generic_components/Select";
import {wait} from "@testing-library/user-event/dist/utils";
import NumberGradientSelector from "../generic_components/NumberGradientSelector";
import ButtonIcon from "../generic_components/ButtonIcon";
import SubmitButton from "../generic_components/SubmitButton";
import Modal from "../generic_components/Modal";
import FileUpload from "../generic_components/FileUpload";
import {useParams} from "react-router-dom";
import Button from "../generic_components/Button";

interface pageProps {
    title : string
}

const EditItinerary: React.FC<pageProps> = ({title}) => {
    const { id } = useParams();

    const [ItineraryTitle, setItineraryTitle, setItineraryTitleDirect] = useStateText();
    const [ItineraryDescription, setItineraryDescription , setItineraryDescriptionDirect] = useStateTextArea();
    const [Tema, setTema, setTemaDirect] = useStateSelect();
    const [Difficulty, setDifficulty, setDifficultyDirect] = useStateNumberDefault(1);
    const [CoverImage, setCoverImage, setCoverImageDirect] = useStateFile();
    const [CoverImagePath, setCoverImagePath] = useState('');

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onModalClose, setOnModalClose] = useStateModal()

    useEffect(() => {
        document.title = title

        const fetchData = async () => {
            const response = await doRequest("/Itinerary/GetItinerary", {id : id}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                let dati_itinerario = response.RISULTATO[0];
                try{
                    setItineraryTitleDirect(dati_itinerario.titolo);
                    setItineraryDescriptionDirect(dati_itinerario.descrizione);
                    setTemaDirect(dati_itinerario.tema);
                    setDifficultyDirect(dati_itinerario.difficolta);
                    setCoverImagePath(dati_itinerario.copertina);
                }catch (e){
                    console.log(e);
                }
            }else{
                console.error("Errore richiesta");
            }
        };

        fetchData();
    }, [id]);


    const handleSubmit = async () => {
        setMostra(false);
        setOnModalClose(() => {});
        let response = await doRequest("/Itinerary/EditItinerary", {ItineraryId : id, ItineraryTitle, ItineraryDescription, Tema, Difficulty, CoverImage}, true);
        //console.log(response);
        if(response !== undefined && response !== null){
            if("RISULTATO" in response){
                let esito = response.RISULTATO;
                setCorpoModale("L'itinerario è stato modificato con successo");
                setTitoloModale("OPERAZIONE EFFETTUATA");
                setMostra(true);
            }
            if("ERRORE" in response){
                let esito = response.ERRORE;
                setCorpoModale("Errore aggiornamento itinerario");
                setTitoloModale("OPERAZIONE FALLITA");
                setMostra(true);
            }
        }else{
            setCorpoModale("Impossibile comunicare col server");
            setTitoloModale("OPERAZIONE FALLITA");
        }
    };

    return (
        <div className="container">
            <Modal id={"ModalResponse"} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} onClose={ItineraryTitle != '' ? () => onModalClose : undefined} />
            <div className="row container-fluid py-4 jumbotron-login border" style={{borderStyle : "solid", borderRadius: "30px"}}>
                <div className="row container-fluid">
                    <h2 className={"h15 margin-contorno-10"}>
                        Informazioni di base
                    </h2>
                    <div className="form-group">
                        <div className="row container-fluid">
                            <div className="col-lg-4">
                                <TextLetters value={ItineraryTitle} onChange={setItineraryTitle}
                                             label={"Titolo itinerario"} obbligatorio={true}/>
                            </div>
                            <div className="col-lg-4">
                                <Select href={"/GetConstants/ItineraryThemeOptions"} id={"tema_itin"}
                                        obbligatorio={true} onChange={setTema} label={"Tema"}
                                        value={Tema}
                                />
                            </div>
                            <div className={"col-lg-4"}>
                                <NumberGradientSelector label={"Seleziona la difficoltà (da 1 a 10)"}
                                                    obbligatorio={true}
                                                    length={10} value={Difficulty} setValue={setDifficulty}/>
                            </div>
                        </div>
                        <div className="row container-fluid">
                            <TextArea id={"desc_itin"} value={ItineraryDescription}
                                      onChange={setItineraryDescription}
                                      label={"Descrizione"}/>
                        </div>
                        <div className="row container-fluid justify-content-center align-content-center py-4">
                            <FileUpload id={"img_copertina"} value={CoverImage} onChange={setCoverImage} label={"Copertina"}/>
                        </div>
                        <div className="row container-fluid justify-content-center align-content-center">
                            {/* <ButtonIcon icon={"fa-check"} href={"/EditItinerary"} operazione={"CREA"} size={"5"} /> */}
                            <div className={"col-lg-6"}>
                                <SubmitButton label={"MODIFICA"} onSubmit={handleSubmit}/>
                            </div>
                            <div className={"col-lg-6"}>
                                <Button label={"GESTISCI TAPPE"} onSubmit={() => {window.location.href = `/EditStops/${id}`}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditItinerary;