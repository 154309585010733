import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import "../styles/TextArea.css"
import {handleErrorCheck} from "../utils";

interface Props {
    id: string;
    value: File | null;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
}

const FileInput: React.FC<Props> = ({ id, value, onChange, label, placeholder = '', obbligatorio = false, errore = "", check = true }) => {

    useEffect(() => {
    }, []);

    const defaultErrore = "Il file non è dell'estensione corretta"
    const [err, setErrore] = useState("");
    const checkErrori = async () => {
        await handleErrorCheck("String", value, setErrore, err, errore, defaultErrore);
    }

    return (
        <div className="">
            <label style={{display: 'block', marginBottom: '8px', fontWeight: 'bold'}}>
                {label}{obbligatorio ? " (*)" : ""}
            </label>
            <input className="form-control form-control-sm" type="file" onChange={onChange}/>
        </div>
    );
};

export default FileInput;