import React, {useEffect, useState} from 'react';
import {
    backend_path, backend_path_slash,
    doRequest, refresh_page, removeColorsFromHTML, truncateHtmlText, truncateText, useStateModal,
} from "../utils";
import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import DifficultyViewer from "../generic_components/DifficultyViewer";
import Label from "../generic_components/Label";
import Img from "../generic_components/Img";
import ButtonIcon from "../generic_components/ButtonIcon";
import Modal from "../generic_components/Modal";
import CircleStars from "../generic_components/CircleStars";

interface pageProps {
    id? : string;
    itinerary_id : number;
    editable?: boolean;
}

const ItineraryDetailsForList: React.FC<pageProps> = ({id = "img_itin", itinerary_id, editable = false}) => {
    const [ItineraryFound , setItineraryFound] = useState(false);

    const [ItineraryTitle, setItineraryTitle] = useState('');
    const [ItineraryDescription, setItineraryDescription] = useState('');
    const [Tema, setTema] = useState('');
    const [Difficulty, setDifficulty] = useState<number | null>(null);
    const [CoverImagePath, setCoverImagePath] = useState('');
    const [AvgItinerary, setAvgItinerary] = useState<number>(-1);

    const [Published, setPublished] = useState<boolean>();

    const [CorpoModale, setCorpoModale, TitoloModale, setTitoloModale, mostra, setMostra, onClose, setOnCloseModal] = useStateModal();

    useEffect(() => {
        const fetchData = async () => {
            const response = await doRequest("/Itinerary/GetItinerary", {id : itinerary_id}, true);
            if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
                let dati_itinerario = response.RISULTATO[0];
                try{
                    setItineraryTitle(dati_itinerario.titolo);
                    setItineraryDescription(dati_itinerario.descrizione);
                    setTema(dati_itinerario.tema);
                    setDifficulty(dati_itinerario.difficolta);
                    setCoverImagePath(dati_itinerario.copertina);
                    setPublished(dati_itinerario.pubblicato);
                    if(dati_itinerario.media_punteggio)
                        setAvgItinerary(dati_itinerario.media_punteggio);
                    setItineraryFound(true);
                }catch (e){
                    console.log(e);
                    setItineraryFound(false);
                }
            }else{
                console.error("Errore richiesta");
                setItineraryFound(false);
            }
        };

        fetchData();
    }, [itinerary_id]);

    const editElement = async () => {
        window.location.href = `/EditItinerary/${id}`
    }

    const publishOrUnpublishItinerary = async (pubblica : string) => {
        const response = await doRequest(`/Itinerary/PublishItinerary/${pubblica}`, {ItineraryId : itinerary_id}, true);
        if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
            setCorpoModale("La pubblicazione è stata gestita");
            setTitoloModale("OPERAZIONE EFFETTUATA");
            setPublished(!Published);
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }else{
            setCorpoModale("Errore nella gestione della pubblicazione");
            setTitoloModale("OPERAZIONE FALLITA");
            setTimeout(() => {
                setMostra(true);
            }, 300)

            setItineraryFound(false);
        }
    }

    const deleteElement = async () => {
        const response = await doRequest("/Itinerary/DeleteItinerary", {id : itinerary_id}, true);
        if (response && "RISULTATO" in response && Array.isArray(response.RISULTATO)) {
            setCorpoModale("Elemento rimosso con successo");
            setTitoloModale("OPERAZIONE EFFETTUATA");
            setTimeout(() => {
                setMostra(true);
            }, 300)
        }else{
            setCorpoModale("Errore in rimozione elemento");
            setTitoloModale("OPERAZIONE FALLITA");
            setTimeout(() => {
                setMostra(true);
            }, 300)

            setItineraryFound(false);
        }
    }

    return (
        <div>
            <Modal onClose={() => {window.location.href = window.location.href + ""}} id={`modal_itinerary_${id}`} corpo={CorpoModale} titolo={TitoloModale} mostra={mostra} setMostra={setMostra}/>
            <div className="row container-fluid py-0">
                <div className="row container-fluid py-0" id={"itinerary_details"}>
                    <div className={"row col-lg-1 col-md-1 d-flex align-items-center"}>
                        <div className={"row"}>
                            <ButtonIcon icon={"cliccabile fa-eye"} size={"2"} operazione={""} onClick={() => {
                                window.location.href = ("/ViewStops/" + itinerary_id)
                            }} alt={"Visualizza itinerario"}/>
                        </div>
                        {editable &&
                            <div className={"row"}>
                                <ButtonIcon icon={"cliccabile fa-pencil"} size={"2"} operazione={""}
                                            onClick={editElement}
                                            alt={"Modifica itinerario"}/>
                            </div>
                        }
                        <br/>
                        {editable &&
                            <div className={"row"}>
                                <ButtonIcon icon={"cliccabile fa-trash"} size={"2"} operazione={""}
                                            onClick={deleteElement}
                                            alt={"Cancella itinerario"}/>
                            </div>
                        }
                        {editable ? (
                                Published ?
                                    <ButtonIcon font_size={"18px"} icon={"fa-share-nodes"} icon_stack={"fa-slash"} size={"2"}
                                                operazione={""} onClick={() => {
                                        publishOrUnpublishItinerary("false")
                                    }} alt={"Rimuovi pubblicazione itinerario"}/>
                                    :
                                    <ButtonIcon font_size={"18px"} icon={"fa-share-nodes"} size={"2"} operazione={""}
                                                alt={"Pubblica itinerario"}
                                                onClick={() => {
                                                    publishOrUnpublishItinerary("true")
                                                }}/>
                            )
                            :
                            <div/>
                        }
                    </div>
                    <div className={"col-lg-5 d-flex position-relative align-items-center justify-content-center"}>
                        <div className={"row container-fluid"}>
                            <Img id={`${id}_coverImage`} id_source={"itintextdata" + itinerary_id} size_coefficient={0.9}
                                 img_path_backend={CoverImagePath} value_override={"100%"} param={"width"}/>
                            <h4>{ItineraryTitle}</h4>
                            <DifficultyViewer length={10} value={Difficulty !== null ? Difficulty : 0} setValue={() => {
                            }} label={"Difficoltà: "}/>
                        </div>
                    </div>
                    <div id={"itintextdata"} className={"col-lg-6 row"}>
                        <div className={"col-lg-8"}>
                            <div dangerouslySetInnerHTML={{__html: truncateHtmlText(removeColorsFromHTML(ItineraryDescription), 350
                                )}}/>
                        </div>
                        <div className={"col-lg-4 row d-flex justify-content-center"}>
                            <CircleStars id={"valutazione_itinerario" + itinerary_id} href_dettaglio={`/ViewFeedback/${itinerary_id}` } title={"Media"} value={AvgItinerary} max={5} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItineraryDetailsForList;