import React, {ChangeEvent, useEffect, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import {doRequest, Option} from "../utils";
import {wait} from "@testing-library/user-event/dist/utils";
import {isEqual} from "lodash";
import Label from "./Label";

interface SelectProps {
    options_override?: Option[];
    href : string;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    value?: string | null;
    label?: string;
    id : string;
    errore?: string;
    check?: boolean;
    obbligatorio?: boolean;
    params? : {};
    icon? : string;
    icon_size? : string;
    icon_onclick? : () => void;
}

/*
OPZIONI DI ESEMPIO
const options = [
    { value: "", label: "", subtext: "" },
    { value: "TEST", label: "TEST", subtext: "TEST" },
];
 */

const Select: React.FC<SelectProps> = ({ href, value, options_override = undefined, icon, icon_size, icon_onclick, onChange, label, id, errore = "", check = true, obbligatorio = false, params = {}  }) => {

    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        if(value){
            $(`#${id}`).selectpicker('destroy').val(value).selectpicker('render');
        }
    }, [value]);

    useEffect(() => {
        const fetchData = async () => {
            if(options_override ){
                return options_override;
            }else{
                const response = await doRequest(href, params, false);
                let res = [];
                if(response !== undefined && !("ERRORE" in response)) {
                    res = response;
                }
                return res;
            }
        };

        fetchData().then((res) => {
            if(!isEqual(res, options))
                setOptions(res);
            setTimeout(() => {
                $(`#${id}`).selectpicker('destroy').selectpicker('render');
            },500);
        });
    }, [ options, options_override]);

    return (
        <div>
            <Label label={label + (obbligatorio ? " (*)" : "")} />
            <div className={"input-group"}>
                <select
                    className={"selectpicker form-control"}
                    data-live-search={true}
                    data-show-subtext="true"
                    //data-size="5"
                    onChange={onChange}
                    id={id}
                >
                    {options && options.map((option, index) => (
                        <option
                            key={index}
                            value={option.value}
                            data-subtext={option.subtext}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                {icon && <span className={"input-group-text cliccabile"} onClick={icon_onclick}><i className={"fa-solid " + icon + " fa-" + icon_size + "x"}/></span>}
            </div>
            <ErrorLabel label={errore}/>
        </div>
    );
};

export default Select;