import React, {ChangeEvent, useState} from 'react';
import "../styles/ButtonIcon.css"

interface ComponentProps {
    icon : string;
    size : string;
    operazione: string;
    href? : string;
    onClick? : () => void;
    vh? : string;
    font_size? : string;
    icon_stack? : string;
    alt?: string;
}

const ButtonIcon: React.FC<ComponentProps> = ({ icon, font_size, size,onClick, icon_stack, alt = "", operazione, vh = 10, href}) => {

    return (
        <div className={"cliccabile row container-fluid justify-content-center align-items-center"}
             onClick={() => {(href) ? window.location.href = href : (onClick && onClick())}}>
            <div className="col-auto">
                <span className={"fa-stack"}>
                    <i className={"fa-solid " + icon + " fa-" + (icon_stack != undefined ? "stack-" : "") + size + "x"}
                       title={alt != "" ? alt : (operazione != "" ? operazione : undefined)}
                        />
                    {icon_stack &&
                        <i className={"fa-solid " + icon_stack + " fa-stack-" + size + "x"}/>
                    }
                </span>
            </div>
            <div className="col-auto" />
                    <div className="col-lg-6">
                        {font_size ?
                            <div style={{fontSize: font_size}}>
                                {operazione}
                            </div>
                            :
                            <h3>
                                {operazione}
                            </h3>
                        }
            </div>
        </div>
    );
};

export default ButtonIcon;