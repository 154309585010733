import React from 'react';

interface ComponentProps {
    label?: string;
}

const Label: React.FC<ComponentProps> = ({ label }) => {
    return (
        (label !== undefined && label != "" && label !== "undefined") ?
            <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
                {label != " " ? label : "\u00A0"}
            </label>
            :
        <div />
    );
};

export default Label;