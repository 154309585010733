import React, {useEffect, useState} from 'react';
import {
    addOrRemoveElementToCarousel,
    backend_path, backend_path_slash,
    doRequest, Option,
    ThemeOptions,
    useStateEmail, useStateFile, useStateModal, useStateSelect,
} from "../utils";

import "../styles/Jumbotron.css"
import "../styles/GenericLink.css"
import {useParams} from "react-router-dom";
import ItineraryDetailsViewer from "../specific_components/ItineraryDetailsViewer";
import FormItineraryStop from "../specific_components/FormItineraryStop";
import {wait} from "@testing-library/user-event/dist/utils";
import Label from "../generic_components/Label";
import Select from "../generic_components/Select";
import CarouselHead from "../generic_components/CarouselHead";
import ButtonIcon from "../generic_components/ButtonIcon";
import ViewerItineraryStop from "../specific_components/ViewerItineraryStop";

interface pageProps {
    title : string
}

const ViewStops: React.FC<pageProps> = ({title}) => {
    const { id } = useParams();
    const [StopsCount, setStopsCount] = useState<number>(-1);
    const [CurrentStopNumber, setCurrentStopNumber] = useState(1);

    useEffect(() => {
        document.title = title
    }, []);

    return (
        <div className={"container"}>
            <ItineraryDetailsViewer editable={false} itinerary_id={id ? parseInt(id) : -1}/>
            <br/>
            <div className="row container-fluid py-4 jumbotron-login border"
                 style={{borderStyle: "solid", borderRadius: "30px"}}>
                <CarouselHead id={id}
                              item_name={"Tappa"}
                              countEndpoint={"/Itinerary/GetStopNumber"}
                              key_parent={"ItineraryId"} key_item={"CurrentStopNumber"}
                              ItemCount={StopsCount} setItemCount={setStopsCount}
                              getSelectOptions={"/Itinerary/GetStopSelect"}
                              CurrentItemNumber={CurrentStopNumber} setCurrentItemNumber={setCurrentStopNumber}
                              innerItems={StopsCount > 0 && [...Array(StopsCount)].map((_, index) => (
                                  <ViewerItineraryStop
                                      currentNumber={CurrentStopNumber}
                                      isCarousel={true}
                                      key={String(StopsCount) + String(index)}
                                      itinerary_id={id ? parseInt(id) : -1}
                                      numero={index + 1}
                                  />
                              ))}/>
            </div>

        </div>
    );
};

export default ViewStops;