import React, {ChangeEvent, useEffect, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import {doRequest, handleErrorCheck} from "../utils";

interface Props {
    id? : string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
    submitWhenEnter?: boolean;
}

const TextLetters: React.FC<Props> = ({ id = "textbox",submitWhenEnter = true, value, onChange, onBlur = () => {}, label, placeholder = '', obbligatorio = false, errore = "", check = true }) => {
    //const defaultErrore = "Il campo deve contenere solo lettere"
    const defaultErrore = "Il campo deve contenere solo lettere, numeri o punti."
    const [err, setErrore] = useState("");

    const checkErrori = async () => {
        await handleErrorCheck("Letters", value, setErrore, err, errore, defaultErrore);
    }

    useEffect(() => {
        if(!submitWhenEnter){
            $(document).on("keydown", `#${id}`, function(event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                }
            });
        }
    }, [value]);

    return (
        <div style={{marginBottom: '16px'}}>
            <label style={{display: 'block', marginBottom: '8px', fontWeight: 'bold'}}>
                {label}{obbligatorio ? " (*)" : ""}
            </label>
            <input
                type="text"
                id={id}
                value={value}
                onChange={onChange}
                onBlur={(e) => {onBlur(); if(check) checkErrori();}}
                placeholder={placeholder}
                style={{
                    padding: '8px',
                    fontSize: '16px',
                    width: '100%',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                }}
            />
            <ErrorLabel label={err} />
        </div>
    );
};

export default TextLetters;