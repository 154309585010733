import React, {useEffect} from 'react';
import "../styles/CircleStars.css"
import "../styles/Jumbotron.css"

interface ComponentProps {
    id: string;
    title: string;
    value: number;
    max: number;
    separated_jumbotron?: boolean;
    href_dettaglio? : string
}

const CircleStars: React.FC<ComponentProps> = ({id, title, value, max, href_dettaglio = "", separated_jumbotron = false}) => {

    useEffect(() => {
        let percentuale = (value / max) * 100;
        if (value > max){
            percentuale = 100;
        }
        const elem_valutazione = document.getElementById(id);
        if(elem_valutazione !== null){
            elem_valutazione.style.background = `conic-gradient(
                    #b3e6c0 0%,
                    #50eb79 ${percentuale}%,
                    #e9ecef 0%
                )`;
        }
        const interval = setInterval(() => {
            if (elem_valutazione !== null) {
                elem_valutazione.style.setProperty('background', `conic-gradient(
                    #b3e6c0 0%,
                    #50eb79 ${percentuale}%,
                    #e9ecef 0%
                )`);
                elem_valutazione.style.setProperty('width', `100px`, "important");
                elem_valutazione.style.setProperty('height', `100px`, "important");

                // Controlla altezza e larghezza
                const currentWidth = elem_valutazione.offsetWidth;
                const currentHeight = elem_valutazione.offsetHeight;

                if (currentWidth !== 100 || currentHeight !== 100) {
                    const newSize = currentWidth !== 100 ? currentWidth : currentHeight;
                    elem_valutazione.style.setProperty('width', `${newSize}px`, "important");
                    elem_valutazione.style.setProperty('height', `${newSize}px`, "important");

                    const spanElement = elem_valutazione.querySelector('span');
                    if (spanElement && newSize != 0) {
                        spanElement.style.setProperty('font-size', `${newSize * 0.33}px`);
                    }
                }
            }
        }, 100 );

        return () => clearInterval(interval);

    }, [value, max, document.getElementById(id)?.offsetHeight, document.getElementById(id)?.offsetWidth]);

    return (
        <div className={(href_dettaglio != "" ? "cliccabile " : "") + "row container fluid d-flex justify-content-center" + (separated_jumbotron ? " jumbotron-second-layer py-5 " : "") } onClick={href_dettaglio != "" ? () => { window.location.href = href_dettaglio } : () => {} }>
            <div className={"row"}>
                <h4>{title}</h4>
            </div>
            <div className={"row d-flex justify-content-center"}>
                <div className="row d-flex justify-content-center circle align-items-center" id={id}>
                    <center>
                        <span>{value > max ? max : (value == -1 ? "-.-" : parseFloat(String(value)).toFixed(1))}</span>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default CircleStars;