import React, {ChangeEvent, useEffect, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import {doRequest, handleErrorCheck} from "../utils";
import Label from "./Label";
import Textbox from "./Textbox";

interface Props {
    id? : string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    label?: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
    submitWhenEnter?: boolean;
    icon?: string;
    icon_size?: string;
    icon_onclick?: () => void;
}

const TextNumber: React.FC<Props> = ({ id = "textbox",icon, icon_size, icon_onclick, submitWhenEnter = true, value, onChange, onBlur = () => {}, label, placeholder = '', obbligatorio = false, errore = "", check = true }) => {
    const defaultErrore = "Il campo deve contenere numerico"
    const [err, setErrore] = useState("");

    const checkErrori = async () => {
        await handleErrorCheck("Number", value, setErrore, err, errore, defaultErrore);
    }

    useEffect(() => {
        if(!submitWhenEnter){
            $(document).on("keydown", `#${id}`, function(event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                }
            });
        }
    }, [value]);

    return (
        <div style={{marginBottom: '16px'}}>
            <Textbox value={value} placeholder={placeholder} onBlur={onBlur} label={label} onChange={onChange} icon_onclick={icon_onclick} icon_size={icon_size} icon={icon} />
            <ErrorLabel label={err} />
        </div>
    );
};

export default TextNumber;