import React, { useState, useEffect } from "react";
import "../styles/DigitalTime.css"

interface ComponentProps {
    time? : string;
}

const DigitalTime : React.FC<ComponentProps> = ({time = ""}) => {

    useEffect(() => {

    }, [time]);

    return (
        <div className={"digital-watch d-flex justify-content-center text-black"}>
            {"DURATA:   "}
            {time && (time.split(":")).map((e : string,index) => (
                (e != "") && (e + ((index == 0) ? ' h ' : (index == 1) ? ' m ' : (index == 2 ? ' s' : "")))
            ))}
        </div>
    );
};

export default DigitalTime;